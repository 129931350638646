import { Button, DatePicker, Drawer, ExportButton, Grid, Input, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { CURRENT_DATE_TIME, excelDateFormat, PENALIZATION_STATUS_TEXT_SPA, prepareBIReport, supabaseClient, TIPSA_AGENCY_CODE, TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED, TIPSA_AGENCY_SERVICE_TYPE_DIRECT, GENDER_MAN, GENDER_NO_GENDER, GENDER_OTHER, GENDER_WOMAN, itemsCompletedCounter } from 'utility'
import { read, utils, writeFile } from 'xlsx'
import { definitions } from 'interfaces'
import { DECREASE_TYPES_MAPPER } from 'utility/mapper'

type generateBIReportProps = {
  filename: string
}

export const GenerateBIReportButton = ({ filename }: generateBIReportProps) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  filename = `${filename} ${CURRENT_DATE_TIME}.xlsx`

  const {
    drawerProps: createDrawerProps,
    close: createClose,
    show: createShow
  } = useDrawerForm<definitions['orders']>({
    action: 'create',
    redirect: false
  })

  const { RangePicker } = DatePicker

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [dates, setDates] = useState<any[]>([])

  const [updateFile, setUpdateFile] = useState(null)
  const [updateFinished, setUpdateFinished] = useState<string | null>(null)

  const getDataOfBIReport = async (datesSelected: any) => {
    const arrayPromise = []

    // group here in promises
    const d1 = new Date(datesSelected[0])
    const d2 = new Date(datesSelected[1])
    // To calculate the time difference of two dates
    const diff = d2.getTime() - d1.getTime()
    // To calculate the no. of days between two dates
    const days = (diff / (1000 * 3600 * 24))

    let data: any[] = []
    const datesRanges: any = []

    const partialNumber = 5
    if (days > partialNumber) {
      // console.log('a lot of days to manage... Fragmenting queries', datesSelected)
      const accDays = Math.round(days / partialNumber)

      const accDate = new Date(datesSelected[0])

      for (let i = 0; i < partialNumber; i++) {
        const fromDate = new Date(accDate)
        accDate.setDate(accDate.getDate() + accDays)
        const dateStart = `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1 > 9) ? fromDate.getMonth() + 1 : '0' + (fromDate.getMonth() + 1)}-${(fromDate.getDate() > 9) ? fromDate.getDate() : '0' + fromDate.getDate()}`
        const dateEnd = `${accDate.getFullYear()}-${(accDate.getMonth() + 1 > 9) ? accDate.getMonth() + 1 : '0' + (accDate.getMonth() + 1)}-${(accDate.getDate() > 9) ? accDate.getDate() : '0' + accDate.getDate()}`

        datesRanges.push([dateStart, (i === (partialNumber - 1)) ? datesSelected[1] : dateEnd])
        // console.log('calling date range -->', [dateStart, (i === (partialNumber - 1)) ? datesSelected[1] : dateEnd])
        arrayPromise.push(prepareBIReport([dateStart, (i === (partialNumber - 1)) ? datesSelected[1] : dateEnd]))
      }

      const results = await Promise.all(arrayPromise)

      let querySucess: any[] = []
      let index = 0
      for await (const r of results) {
        if (r.error) {
          // console.log(index, datesRanges[index])
          const dataByError = await getDataOfBIReport(datesRanges[index])
          querySucess = [...querySucess, ...dataByError]
        }
        if (r.data) querySucess = [...querySucess, ...r.data]
        index += 1
      }

      data = querySucess
    } else {
      const { data: res, error } = await prepareBIReport(datesSelected)
      if (error) {
        console.error(error)
        data = await getDataOfBIReport(datesSelected)
      }
      data = res
    }
    return data
  }

  const generateBIReport = async () => {
    const { data: methods } = await supabaseClient.from('payment_methods').select('id, name').eq('deleted', false)

    const headerNames = {
      created_at: t('order.fields.createdAt'),
      alias: t('order.fields.alias'),
      order_id: t('order.fields.id'),
      invoice: t('order.fields.invoiceNumber'),
      invoice_date: t('order.fields.invoiceDate'),
      payment_method: t('order.biReport.paymentMethod'),
      location: t('order.biReport.location'),
      package_number: t('order.biReport.package_number'),
      itemsCompleted: t('order.fields.itemsCompleted'),
      marketplace: t('order.fields.marketplace'),
      type: t('order.fields.type'),
      device: t('order.biReport.device'),
      island: t('order.biReport.island'),
      postal_code: t('order.biReport.postalCode'),
      client_name: t('order.biReport.clientName'),
      client_email: t('order.biReport.clientEmail'),
      gender: t('order.biReport.gender'),
      birthday: t('order.biReport.birthday'),
      user_id: t('order.fields.userId'),
      registered_at: t('order.biReport.registeredAt'),
      status: t('order.fields.status'),
      delivered_date: t('order.biReport.delivered_date'),
      cancelation_reason: t('order.biReport.cancelReason'),
      return_reason: t('order.biReport.returnReason'),
      shipping_method: t('order.fields.shipmentMethod'),
      shipper_type: t('order.fields.shipmentType'),
      tracking_id: t('order.biReport.trackingId'),
      delivery_note: t('order.biReport.deliveryNote'),
      billable_volume: t('order.biReport.volumen'),
      volProv: t('order.biReport.volProv'),
      cteProv: t('order.biReport.cteProv'),
      codProv: t('order.biReport.codProv'),
      subtotal: t('order.fields.subtotal'),
      syniva_price: t('order.fields.synivaPrice'),
      // total_shipping_price: t('order.fields.totalShippingPrice'),
      shipping_price: t('order.fields.shippingPrice'),
      shipping_price_mainland: t('order.fields.shippingPriceMainland'),
      shipping_insurance_price: t('order.fields.shippingInsurance'),
      igic: t('order.fields.igic'),
      custom_order_price: t('order.fields.customOrderPrice'),
      total_initial: t('order.fields.initial_total'),
      supplements_price_change: t('order.biReport.supplementPriceChange'),
      supplements_peninsula_transport: t('order.fields.supplementShipmentMainland'),
      supplements_transport: t('order.fields.supplementShipment'),
      supplements_reduced_iva: t('order.biReport.supplementIVA'),
      supplements_reduced_iva_second_hand: t('order.biReport.supplementIVASecondHand'),
      supplements_reduced_iva_breakdown: t('order.biReport.supplementIVABreakdown'),
      supplements_reduced_iva_eu: t('order.biReport.supplementIVAEU'),
      supplements_other: t('order.biReport.supplementOther'),
      supplements_tariff: t('order.biReport.supplementTariff'),
      supplements_igic: t('order.biReport.supplementIGIC'),
      supplements_shipping_insurance: t('order.biReport.supplementShippingInsurance'),
      supplements_penalization: t('order.biReport.penalization'),
      supplements_refund: t('order.biReport.refund'),
      supplements_refundStore: t('order.biReport.refundStore'),
      supplements_refundByCancelation: t('order.biReport.refundByCancelation'),
      supplements_refundTransport: t('order.biReport.refundTransport'),
      supplements_vol: t('order.fields.volumetricSupplements'),
      estimated_costs: t('order.biReport.estimatedCost'),
      refunds_client_sum: t('order.biReport.refundsClient'),
      refunds_client_status: t('order.biReport.refundsClientStatus'),
      refunds_supplier_sum: t('order.biReport.refundsSupplier'),
      refunds_supplier_status: t('order.biReport.refundsSupplierStatus'),
      purchase_amount: t('order.biReport.buy'),
      purchase_iva_amount: t('order.biReport.buyIva'),
      purchases_completed: t('order.biReport.purchaseStatus'),
      discount: t('order.biReport.discount'),
      wallet_amount_used: t('order.biReport.walletAmountUsed'),
      promotional_code: t('order.biReport.promotionalCode'),
      total: t('order.fields.total'),
      decrease_costs: t('order.biReport.decrease_costs'),
      decrease_types: t('order.biReport.decrease_types'),
      atypical: t('order.fields.atypical')
    }

    // console.log(dates)
    const data = await getDataOfBIReport(dates)

    if (!data || data.length === 0) {
      return []
    }
    // Sort by Date
    const sortedData = data.sort((a: any, b: any) => {
      return Date.parse(a.order.created_at) - Date.parse(b.order.created_at)
    })

    for (const o of sortedData) {
      // Map data retreived with names
      o.client_name = o.user.client_name
      o.client_email = o.user.client_email
      o.registered_at = o.user.registered_at
      o.gender = o.user.gender
      o.birthday = o.user.birthday
      o.postal_code = o.user.postal_code
      o.island = o.user.island

      o.created_at = o.order.created_at
      o.status = o.order.status
      o.order_id = o.order.order_id
      o.user_id = o.order.user_id
      o.alias = o.order.alias
      o.device = o.order.device
      o.payment_method = o.order.payment_method_id
      o.delivered_date = o.order.delivered_date
      o.location = o.order.location
      o.cancelation_reason = o.order.cancelation_reason
      o.return_reason = o.order.return_reason
      o.discount = o.order.discount
      o.wallet_amount_used = o.order.wallet_amount_used
      o.promotional_code = o.order.promotional_code
      o.provider_cost = o.order.provider_cost
      o.package_number = o.order.package_number
      o.invoice = o.order.invoice
      o.type = o.order.type
      o.subtotal = o.order.subtotal
      o.syniva_price = o.order.syniva_price
      o.shipping_price = o.order.shipping_price
      o.shipping_insurance_price = o.order.shipping_insurance_price
      o.igic = o.order.igic
      o.custom_order_price = o.order.custom_order_price
      o.purchase_conditions = o.order.purchase_conditions
      o.total_initial = o.order.total
      o.invoice_date = o.order.invoice_date
      o.total = o.order.total
      o.decrease_costs = o.order.decrease_costs
      o.decrease_types = (o.order.decrease_types !== undefined && o.order.decrease_types !== null) ? t(`decrease_types.${(DECREASE_TYPES_MAPPER as any)[o.order.decrease_types]}`) : ''
      o.atypical = o.order.atypical

      o.purchase_amount = o.purchases.purchase_amount
      o.purchase_iva_amount = o.purchases.purchase_iva_amount
      o.purchases_completed = o.purchases.purchases_completed
      o.purchases_total = o.purchases.purchases_total

      o.shipper_type = o.shipment.shipper_type
      o.sh_type = o.shipment.sh_type
      o.tracking_id = o.shipment.tracking_id
      o.delivery_note = o.shipment.delivery_note
      o.shipping_method = o.shipment.shipping_method

      // Counting all suplements data
      if (o.supplement_array && o.supplement_array.length > 0) {
        const sups = o.supplement_array
        o.supplements_price_change = 0
        o.supplements_peninsula_transport = 0
        o.supplements_transport = 0
        o.supplements_reduced_iva = 0
        o.supplements_reduced_iva_second_hand = 0
        o.supplements_reduced_iva_breakdown = 0
        o.supplements_reduced_iva_eu = 0
        o.supplements_reduced_iva_shipment = 0
        o.supplements_other = 0
        o.supplements_tariff = 0
        o.supplements_igic = 0
        o.supplements_shipping_insurance = 0
        o.supplements_penalization = 0
        o.supplements_refund = 0
        o.supplements_refundStore = 0
        o.supplements_refundByCancelation = 0
        o.supplements_refundTransport = 0
        o.supplements_vol = 0
        sups.forEach((s: any) => {
          // Example of supplement in array
          // [id, volumetric, description, supplement, order_id]
          if (s[0] && s[0] !== null) {
            if (s[1] === true) {
              o.supplements_vol += s[3]
            } else {
              if (s[2] === 'priceChange') o.supplements_price_change += s[3]
              else if (s[2] === 'transportToPeninsula') o.supplements_peninsula_transport += s[3]
              else if (s[2] === 'transportSupplement') o.supplements_transport += s[3]
              else if (s[2] === 'reducedIva') o.supplements_reduced_iva += s[3]
              else if (s[2] === 'reducedIvaSecondHand') o.supplements_reduced_iva_second_hand += s[3]
              else if (s[2] === 'reducedIvaBreakdown') o.supplements_reduced_iva_breakdown += s[3]
              else if (s[2] === 'reducedIvaEU') o.supplements_reduced_iva_eu += s[3]
              else if (s[2] === 'reducedIvaShipment') o.supplements_reduced_iva_shipment += s[3]
              else if (s[2] === 'other') o.supplements_other += s[3]
              else if (s[2] === 'tariff') o.supplements_tariff += s[3]
              else if (s[2] === 'igic') o.supplements_igic += s[3]
              else if (s[2] === 'shippingInsurancePrice') o.supplements_shipping_insurance += s[3]
              else if (s[2] === 'penalization') o.supplements_penalization += s[3]
              else if (s[2] === 'refund') o.supplements_refund += s[3]
              else if (s[2] === 'refundStore') o.supplements_refundStore += s[3]
              else if (s[2] === 'refundByCancelation') o.supplements_refundByCancelation += s[3]
              else if (s[2] === 'refundTransport') o.supplements_refundTransport += s[3]
            }
          }
        })
      }

      const refundTransportSupplements = (o.supplements_refundTransport + o.supplements_peninsula_transport + o.supplements_transport) || 0
      const shippingPrice = (o?.shipping_price || 0) + refundTransportSupplements

      const initialTotal = (o?.syniva_price || 0) + (o?.shipping_insurance_price || 0) + shippingPrice + (o?.igic || 0) + ((o.type.toLowerCase() === 'personalizado') ? o?.custom_order_price || 0 : 0)
      o.total_initial = initialTotal

      if (o.purchases_completed && o.purchases_total && o.purchases_total > 0) {
        if (o.purchases_completed === o.purchases_total) o.purchases_completed = t('order.biReport.purchasesCompleted')
        else if (o.purchases_completed < o.purchases_total && o.purchases_completed > 0) o.purchases_completed = t('order.biReport.purchasesPartial')
        else o.purchases_completed = t('order.biReport.purchasesPending')
      } else {
        o.purchases_completed = t('order.biReport.noPurchases')
      }
      o.purchases_total = ''

      if (o.delivered_date && o.delivered_date !== '') {
        const date = new Date(Date.parse(o.delivered_date))
        o.delivered_date = excelDateFormat(date.toISOString())
      }
      if (o.invoice_date && o.invoice_date !== '') {
        const idate = new Date(Date.parse(o.invoice_date))
        o.invoice_date = excelDateFormat(idate.toISOString())
      }
      if (o.refunds_client_status || o.refunds_client_status === 0) {
        o.refunds_client_status = t(`order.status.${o.refunds_client_status}`)
      }
      if (o.refunds_supplier_status || o.refunds_supplier_status === 0) {
        o.refunds_supplier_status = t(`order.status.${o.refunds_supplier_status}`)
      }
      if (o.sh_type) {
        if (o.delivery_note) {
          o.delivery_note = TIPSA_AGENCY_CODE + (o.sh_type.toLowerCase().includes('directo') ? TIPSA_AGENCY_SERVICE_TYPE_DIRECT : TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED) + o.delivery_note
        }
        delete o.sh_type
      }
      if (o.cancelation_reason) {
        o.cancelation_reason = t(`order.fields.options.${o.cancelation_reason}`)
      }
      if (o.return_reason) {
        o.return_reason = t(`order.fields.options.${o.return_reason}`)
      }

      if (o.created_at) {
        const date = excelDateFormat(o.created_at)
        o.created_at = date
      }

      if (o.registered_at) {
        const registeredDate = excelDateFormat(o.registered_at)
        o.registered_at = registeredDate
      }
      if (o.gender) {
        if (o.gender === GENDER_NO_GENDER) o.gender = t('order.fields.noGender')
        if (o.gender === GENDER_MAN) o.gender = t('order.fields.man')
        if (o.gender === GENDER_WOMAN) o.gender = t('order.fields.woman')
        if (o.gender === GENDER_OTHER) o.gender = t('order.fields.other')
      } else {
        o.gender = ''
      }

      if (o.status === PENALIZATION_STATUS_TEXT_SPA) {
        o.shipping_price = 0
      }

      if (o.birthday) {
        const dateNow = Date.now() - new Date(o.birthday).getTime()
        const age = new Date(dateNow)

        o.birthday = age.getUTCFullYear() - 1970
      } else {
        o.birthday = ''
      }

      if (o.provider_cost) {
        o.volProv = o.provider_cost.volProv || ''
        o.cteProv = o.provider_cost.cteProv || ''
        o.codProv = o.provider_cost.codProv || ''
      }

      if (o.type.toLowerCase() === 'interno') {
        o.custom_order_price = 0
      }

      const volumetricSupplements = o.supplements_vol || 0
      const nonVolumetricSupplements = (o.supplements_other || 0) + (o.supplements_tariff || 0) + (o.supplements_penalization || 0)
      const refundSupplements = (o.supplements_refund || 0) + (o.supplements_refundStore || 0)

      o.total = initialTotal + volumetricSupplements + nonVolumetricSupplements + refundSupplements - (Math.abs(o.discount || 0)) - (Math.abs(o.wallet_amount_used || 0))

      o.shipping_price_mainland = 0
      // const auxShippingPrice = o.shipping_price

      if (o.shipping_to_madrid !== 0) {
        o.shipping_price_mainland = o.shipping_to_madrid
      } else if (o.marketplace.free_shipment_price > 0 && o.marketplace.free_shipment_price > o.syniva_price) {
        o.shipping_price_mainland = o.marketplace.shipment_price
      } else {
        if (o.purchase_conditions && o.purchase_conditions.shipment_method_price) {
          o.shipping_price_mainland = ((o.purchase_conditions.shipping_price > o.purchase_conditions.shipment_method_price) ? o.purchase_conditions.shipping_price - o.purchase_conditions.shipment_method_price : 0)
        }
      }
      o.shipping_price = parseFloat((o.shipping_price + (o.supplements_transport || 0) + (o.supplements_refundTransport || 0) - o.shipping_price_mainland).toFixed(2))
      o.shipping_price_mainland += (o.supplements_peninsula_transport || 0)

      o.marketplace = o.marketplace.marketplace

      if (methods && methods.length > 0) {
        o.payment_method = methods.find((x: { id: number }) => x.id === o.payment_method)?.name || o.payment_method
      }

      if (o.order.items_array && o.order.items_array.length > 0) {
        const items = o.order.items_array
        // eslint-disable-next-line camelcase
        const itemsFormatted: { delivered_warehouse: boolean, disabled: boolean }[] = []
        items.forEach((i: any) => {
          // Example of supplement in array
          // [id, order_id, delivered_warehouse, disabled]
          if (i[0] && i[0] !== null) {
            itemsFormatted.push({ delivered_warehouse: i[2], disabled: i[3] })
          }
        })
        o.itemsCompleted = itemsCompletedCounter(itemsFormatted)
      }

      delete o.shipping_to_madrid
      delete o.order.items_array
    }

    sortedData.unshift(headerNames)
    return sortedData
  }

  const autofitColumns = (json: any[]) => {
    const jsonKeys = Object.keys(json[0])
    const objectMaxLength: any = []

    for (let i = 0; i < json.length; i++) {
      const value = json[i]
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10
        } else if (value[jsonKeys[j]] instanceof Date) {
          objectMaxLength[j] = 20
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0
          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
        }
      }

      const key = jsonKeys
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length
      }
    }

    return objectMaxLength.map((w: number) => {
      return { width: w }
    })
  }

  const exportToExcel = async () => {
    setLoading(true)
    const jsonData = await generateBIReport()
    if (jsonData === null) {
      setError(true)
    } else {
      const worksheet = utils.json_to_sheet(jsonData, { skipHeader: true, cellDates: true, dateNF: 'dd/mm/yyyy' })
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      worksheet['!cols'] = autofitColumns(jsonData)
      setLoading(false)
      writeFile(workbook, filename, { cellDates: true })

      createClose()
    }
  }

  const changeDates = (datesInput: any) => {
    const arrayDates = [datesInput[0], datesInput[1]]
    const dateStart = `${arrayDates[0].get('year')}-${(arrayDates[0].get('month') + 1 > 9) ? arrayDates[0].get('month') + 1 : '0' + (arrayDates[0].get('month') + 1)}-${(arrayDates[0].get('date') > 9) ? arrayDates[0].get('date') : '0' + arrayDates[0].get('date')}`
    const dateEnd = `${arrayDates[1].get('year')}-${(arrayDates[1].get('month') + 1 > 9) ? arrayDates[1].get('month') + 1 : '0' + (arrayDates[1].get('month') + 1)}-${(arrayDates[1].get('date') > 9) ? arrayDates[1].get('date') : '0' + arrayDates[1].get('date')}`
    setDates([dateStart, dateEnd])
  }

  const handleFile = (e: any) => {
    setUpdateFile(e.target.files[0])
  }

  const updateOrderProviderCost = async (orderId: Number, volProv: Number, cteProv: Number, codProv: Number | string) => {
    await supabaseClient.from('orders')
      .update({
        provider_cost: {
          volProv: volProv,
          cteProv: cteProv,
          codProv: codProv
        },
        updated_by: supabaseClient.auth.user()?.email
      })
      .eq('id', orderId)
  }

  const updateProviderCosts = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setUpdateFinished(null)

    const reader = new FileReader()
    reader.onload = async function (e) {
      try {
        const data = e?.target?.result
        const readedData = read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        const dataParse: any = utils.sheet_to_json(ws, { header: 1 })

        for (const m of dataParse) {
          if (typeof m[1] !== 'string') {
            const row = { orderId: Number(m[0]), volProv: Number(m[1]), cteProv: Number(m[2]), codProv: Number(m[3]) || '' }
            await updateOrderProviderCost(row.orderId, row.volProv, row.cteProv, row.codProv)
          }
        }

        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('ok')
      } catch (err) {
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('error')
      }
    }

    if (updateFile) {
      reader.readAsBinaryString(updateFile)
    }
  }

  const downloadTemplate = () => {
    const templateData = [['Id-1', 1.21, 7.00, 24], ['Id-1', 2.13, 9.00, 75]]
    const rowList = templateData.map((x: any) => {
      return { 'ID pedido': x[0], 'Vol Prov': x[1], 'Cte. Prov': x[2], 'Cod Prov': x[3] }
    })
    const worksheet = utils.json_to_sheet(rowList, { skipHeader: false })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    writeFile(workbook, 'provider_costs_template.xlsx')
  }

  return <>
    <Button type="primary" onClick={() => createShow()}>
      {t('buttons.generateBI')}
    </Button>
    <Drawer
      {...createDrawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('order.biReport.labelDateBI')}
        </Typography.Text>
        <RangePicker showTime={false} onChange={changeDates} />

        <Button style={{ marginTop: '1%' }} type="primary" disabled={(dates.length < 2)} loading={loading} onClick={() => exportToExcel()}>
          {t('buttons.generateBI')}
        </Button>
      </Space>

      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('order.biReport.labelProviderCosts')}
        </Typography.Text>

        <ExportButton
          style={{ marginBottom: '1rem' }}
          onClick={() => downloadTemplate()}
          size='small'
        >
          {t('order.biReport.labelTemplate')}
        </ExportButton>

        <Typography.Text>{t('marketplace.actions.uploadExcel')}</Typography.Text>
        <Input
          accept='.xlsx'
          bordered={false}
          size='middle'
          type='file'
          key='exceluploader'
          onChange={handleFile}
        />
        <Button disabled={updateFile === null} loading={loading} type='primary'
          onClick={(e: any) => updateProviderCosts(e)}
        >
          {t('order.biReport.labelUpdate')}
        </Button>

        {loading && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('order.biReport.labelUpdating')}
        </Typography>}
        {updateFinished && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t(`order.biReport.labelFinish${updateFinished}`)}
        </Typography>}

      </Space>

      {(error)
        ? <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
          <Typography.Text>
            {t('order.biReport.error')}
          </Typography.Text>
        </Space>
        : null}
    </Drawer>
  </>
}
