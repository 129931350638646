import { useTranslate } from '@pankod/refine-core'
import { Modal, ModalProps } from '@pankod/refine-antd'
import { Dispatch, SetStateAction, useState } from 'react'
import { supabaseClient } from 'utility/supabaseClient'
import { updateCalculatedTotalOrder } from 'utility/supabaseApi'

type DeleteModalProps = {
  modalProps: ModalProps,
  close: any,
  id: number
  orderId?: number | null,
  entity: string
  tableQueryResult?: any
  relatedMarketOrders?: any
  setRefetch?: Dispatch<SetStateAction<boolean>>
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ modalProps, close, id, orderId, entity, tableQueryResult, relatedMarketOrders, setRefetch }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const t = useTranslate()
  const handleOk = async () => {
    setConfirmLoading(true)

    if (entity === 'users') {
      const { data: user, error: userError } = await supabaseClient.from(entity)
        .select('id, email, supabase_user_id')
        .eq('id', id)
        .single()

      if (userError) return

      const { data: rpcData, error: rpcError } = await supabaseClient.rpc('delete_user', { uuid: user.supabase_user_id, email_to_remove: user.email + ` - DELETED ${user.supabase_user_id}` })
      if (rpcError) console.error(rpcError?.message)
      if (rpcData) console.log('removed successfully')
    } else {
      const { data } = await supabaseClient.from(entity)
        .update({
          deleted: true,
          deleted_by: supabaseClient.auth.user()?.email,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', id)
        .single()

      if (entity === 'market_orders') {
        await supabaseClient.from(entity)
          .update({
            deleted: true,
            deleted_by: supabaseClient.auth.user()?.email,
            updated_by: supabaseClient.auth.user()?.email
          })
          .in('id', relatedMarketOrders.map((mo: any) => mo.id))
      }

      if (entity === 'supplements' && orderId && data.description === 'unified') {
        await supabaseClient.from(entity)
          .update({
            parent: null,
            updated_by: supabaseClient.auth.user()?.email
          })
          .eq('order_id', orderId)
          .eq('parent', data.id)
      }

      if (orderId) await updateCalculatedTotalOrder(orderId)
    }

    setConfirmLoading(false)
    close()

    setRefetch?.(true)

    if (entity === 'market_orders') tableQueryResult()
    else tableQueryResult.refetch()
  }

  return (
    <Modal
      {...modalProps}
      title={t('actions.deleteItem')}
      centered
      onOk={handleOk}
      onCancel={close}
      confirmLoading={confirmLoading}
    >
      {t('actions.confirmationDelete')} {(entity === 'market_orders') && t('actions.marketOrderAdvise')}
    </Modal>
  )
}
