import { useTranslate } from '@pankod/refine-core'
import { DatePicker, Input, InputNumber, Modal, ModalProps, Radio, Select, Typography } from '@pankod/refine-antd'
import { useState } from 'react'
import { definitions } from 'interfaces'
import { ABSENT_TYPE_ILLNESS, ABSENT_TYPE_PERMISSION, ABSENT_TYPE_PERSONAL, ABSENT_TYPE_VACATION, HOURS, MULTIPLES_DATES, ONE_DAY, supabaseClient } from 'utility'
import dayjs from 'dayjs'

type CreateAbsentModalProps = {
  modalProps: ModalProps,
  close: any
  user: definitions['users'] | undefined
}

export const CreateAbsentModal: React.FC<CreateAbsentModalProps> = ({ modalProps, close, user }) => {
  const t = useTranslate()

  const [loading, setLoading] = useState<boolean>(false)

  const [type, setType] = useState<string | undefined>()
  const [duration, setDuration] = useState<string>(ONE_DAY)
  const [dateStart, setDateStart] = useState<any>(dayjs(new Date(Date.now())))
  const [dateEnd, setDateEnd] = useState<any>(dayjs(new Date(Date.now())))
  const [hours, setHours] = useState<number | undefined>(undefined)
  const [reason, setReason] = useState<string | undefined>(undefined)

  const handleOk = async () => {
    if (user) {
      setLoading(true)

      const absent = {
        user_id: user.id,
        date_start: dateStart,
        date_end: dateEnd,
        hours: hours,
        reason: reason,
        type: type,
        updated_at: new Date(Date.now()).toISOString(),
        updated_by: user.email
      }

      await supabaseClient.from<definitions['employee_absent']>('employee_absent').insert(absent)

      resetFields()
      setLoading(false)
      close()
      window.location.reload()
    }
  }

  const resetFields = () => {
    setType(undefined)
    setDuration(ONE_DAY)
    setDateStart(undefined)
    setDateEnd(undefined)
    setHours(undefined)
    setReason(undefined)
  }

  const setDurationType = (typeDuration: string) => {
    setDuration(typeDuration)
    setDateStart(undefined)
    setDateEnd(undefined)
    setHours(undefined)
  }

  return (
    <Modal
      {...modalProps}
      title={t('profile.createAbsent')}
      centered
      okText={t('profile.createAbsentSave')}
      onOk={handleOk}
      onCancel={close}
      confirmLoading={loading}
    >
      <div>
        <Select
          style={{ width: '100%' }}
          placeholder={t('employee_absent.fields.type')}
          value={type}
          options={[
            { label: t('-'), value: undefined },
            { label: t('profile.form.illness'), value: ABSENT_TYPE_ILLNESS },
            { label: t('profile.form.personal'), value: ABSENT_TYPE_PERSONAL },
            { label: t('profile.form.permission'), value: ABSENT_TYPE_PERMISSION },
            { label: t('profile.form.vacation'), value: ABSENT_TYPE_VACATION }
          ]}
          onChange={(value) => {
            setType(value)
          }}
        />

        <div style={{ width: '100%', marginTop: '1rem' }}>
          <Typography style={{ marginBottom: '0.5rem' }}>{t('employee_absent.fields.duration')}</Typography>
          <Radio.Group value={duration}>
            <Radio onClick={() => setDurationType(ONE_DAY)} value={ONE_DAY}>{t('profile.form.oneDay')}</Radio>
            <Radio onClick={() => setDurationType(MULTIPLES_DATES)} value={MULTIPLES_DATES}>{t('profile.form.multiplesDates')}</Radio>
            <Radio onClick={() => setDurationType(HOURS)} value={HOURS}>{t('profile.form.hours')}</Radio>
          </Radio.Group>
        </div>

        {(duration === ONE_DAY) && <div style={{ width: '100%', marginTop: '1rem' }}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder={t('employee_absent.fields.date')}
            onChange={(value: any) => {
              setDateStart(value)
            }
          } />

        </div>}
        {(duration === MULTIPLES_DATES) && <div style={{ width: '100%', marginTop: '1rem', display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder={t('employee_absent.fields.dateStart')}
            onChange={(value: any) => {
              setDateStart(value)
            }
          } />
          <DatePicker
            style={{ width: '100%' }}
            placeholder={t('employee_absent.fields.dateEnd')}
            onChange={(value: any) => {
              setDateEnd(value)
            }
          } />
        </div>}
        {(duration === HOURS) && <div style={{ width: '100%', marginTop: '1rem', display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
          <DatePicker
            style={{ width: '50%' }}
            placeholder={t('employee_absent.fields.date')}
            onChange={(value: any) => {
              setDateStart(value)
            }
          } />
          <InputNumber
            style={{ width: '50%' }}
            placeholder={t('employee_absent.fields.hours')}
            min={1}
            max={8}
            value={hours}
            onChange={(value: number) => setHours(value)}
          />
        </div>}

        <Input
          style={{ width: '100%', marginTop: '1rem' }}
          placeholder={t('employee_absent.fields.reason')}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
    </Modal>
  )
}
