/* eslint-disable camelcase */
import { useTranslate } from '@pankod/refine-core'

import {
  Input,
  InputNumber,
  Row,
  Col,
  Typography,
  Button,
  Icons
} from '@pankod/refine-antd'
import { getIvaOfAmount } from 'utility'
import { useEffect, useState } from 'react'

type InvoiceFormProps = {
  edit?: boolean
  relatedPurchases?: any[]
  setInvoicesToAdd: any
}

export const InvoicesForm: React.FC<InvoiceFormProps> = ({
  edit,
  relatedPurchases,
  setInvoicesToAdd
}) => {
  const t = useTranslate()

  const [invoices, setInvoices] = useState<any[]>(Array.from(new Array((edit) ? relatedPurchases?.length : 1)))

  if (!edit) {
    useEffect(() => {
      setInvoices([{ purchase_amount: 0, iva_type: '21', iva_amount: 0 }])
    }, [])
  }

  if (edit) {
    useEffect(() => {
      // Preload data
      const purchasesData: { purchase_amount: number; iva_type: string; iva_amount: number, id: number }[] = []
      relatedPurchases?.forEach((mo: any) => {
        purchasesData.push({
          purchase_amount: mo.purchase_amount,
          iva_type: mo.iva_type,
          iva_amount: mo.iva_amount,
          id: mo.id
        })
      })

      setInvoices([...purchasesData])
    }, [relatedPurchases])
  }

  useEffect(() => {
    setInvoicesToAdd([...invoices])
  }, [invoices])

  const updateInvoiceValue = async (field: string, index: number, value: any) => {
    const dUpdate = [...invoices]
    const item = Object.assign({}, invoices[index])
    item[`${field}`] = value

    if (field !== 'iva_amount') {
      const type = item.iva_type || 0
      const purchaseAmount = item.purchase_amount || 0
      const amount = purchaseAmount - getIvaOfAmount(purchaseAmount, (type && parseInt(type) >= 0) ? parseInt(type) : ((!type) ? 21 : 0))

      item.iva_amount = amount.toFixed(2)
    }

    dUpdate[index] = item
    setInvoices([...dUpdate])
  }

  const addInvoice = async () => {
    const dUpdate = [...invoices]
    dUpdate.push({
      purchase_amount: 0,
      iva_type: '21',
      iva_amount: 0
    })
    await setInvoices(dUpdate)
  }

  const removeInvoices = async (index: number) => {
    if (invoices.length > 1) {
      // eslint-disable-next-line no-unused-vars
      const removedSup = [...invoices.splice(index, 1)]
      await setInvoices([...invoices])
    }
  }

  return (
    <>
      <div>
        <Typography style={{ fontSize: 16, marginBottom: '10px', fontWeight: 700 }}>{`${t('market_order.fields.invoiceManagement')}`}</Typography>
        <div style={{ display: 'flow-root', marginTop: '-2.25rem', marginBottom: 10 }}>
          <Button style={{ float: 'right' }} onClick={addInvoice}>
            {t('market_order.fields.addInvoice')}
          </Button>
        </div>
      </div>

      {invoices.map((i: any, index: number) => {
        return <div key={`invoices-${index}`}>
          <Row key={index} gutter={[64, 0]} wrap>
            <Col xs={24} lg={3}>
              <Button disabled={index === 0} size='small' onClick={() => removeInvoices(index)}
                style={{ backgroundColor: 'red', color: 'white', marginTop: '70%' }}
              >
                <Icons.DeleteOutlined />
              </Button>
            </Col>
            <Col xs={24} lg={7}>
              <Typography style={{ fontSize: 14, marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}>{t('market_order.fields.purchaseAmount')}</Typography>
              <InputNumber required value={invoices[index]?.purchase_amount || 0} decimalSeparator=',' onChange={(value) => updateInvoiceValue('purchase_amount', index, value ?? 0)} />
            </Col>
            <Col xs={24} lg={7}>
                <Typography style={{ fontSize: 14, marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}>{t('purchases.fields.ivaType')}</Typography>
                <Input value={invoices[index]?.iva_type || 0} onChange={(event) => updateInvoiceValue('iva_type', index, event.target?.value ?? 0)} />
            </Col>
            <Col xs={24} lg={7}>
                <Typography style={{ fontSize: 14, marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}>{t('purchases.fields.ivaValue')}</Typography>
                <Input value={invoices[index]?.iva_amount || 0} readOnly={true} />
            </Col>
          </Row>
        </div>
      })}
    </>
  )
}
