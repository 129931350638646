import { DateField, Card, Row, Col, Descriptions, Tag, useDrawerForm, EditButton, Divider, Icons, Badge, Button, useModal, Space, Input, InputNumber, Typography, DatePicker } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

import dayjs from 'dayjs'
import { definitions } from 'interfaces'
import { DEFAULT_DATE_TIME_FORMAT, ADMITTED_STATUS, API_URL, DEFAULT_DATE_FORMAT, RETURN_STATUS, SUPERUSER_LIST } from 'utility/constants'
import { currency } from 'utility/currency'
import { getColorLabel } from 'utility/colorLabel'
import { EditOrderDetails } from 'components/orderDetails/edit'
import { capitalizeFirstLetter } from 'utility/capitalizeFirstLetter'
import { MapEstimatedCostsStatusIDToText, getOrderIdAlias, itemsCompletedCounter, lockOrUnlockOrder, supabaseClient } from 'utility'
import { CommentModal } from './commentsModal'
import { HistoryModal } from './historyModal'
import { useState } from 'react'
import { IssueDrawer } from 'pages'
import { DecreaseDrawer } from 'components/decrease/drawer'
import { DECREASE_TYPES_MAPPER } from 'utility/mapper'

type OrderDetailsProps = {
  order: definitions['Order'] & { issues?: definitions['issues'] } | undefined
  refetchFn: () => void
};

export const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    saveButtonProps: editSaveButtonProps,
    close: editClose,
    id
  } = useDrawerForm<definitions['orders']>({
    action: 'edit',
    resource: 'orders',
    redirect: false
  })

  const {
    drawerProps: createIssueDrawerProps,
    formProps: createIssueFormProps,
    show: createIssueShow,
    close: createIssueClose
  } = useDrawerForm<definitions['issues']>({
    action: 'create',
    resource: 'issues',
    redirect: false
  })

  const {
    drawerProps: editdecreaseDrawerProps,
    formProps: editdecreaseFormProps,
    show: editdecreaseShow,
    close: editdecreaseClose
  } = useDrawerForm<definitions['issues']>({
    action: 'edit',
    resource: 'orders',
    redirect: false
  })

  const t = useTranslate()
  let order = props?.order

  let orderFinalTotal = order?.total ?? 0
  let invoiceButtonAction = t('buttons.viewDraft')
  const historicButtonAction = t('buttons.viewOrderHistory')
  const regenerateInvoiceAction = t('buttons.regenerateInvoice')
  let volumetricSupplements = 0
  let nonVolumetricSupplements = 0
  let refundTransportSupplements = 0
  let refundSupplements = 0
  let shippingPrice = order?.shipping_price || 0

  if (order) {
    volumetricSupplements = order.supplements
      .filter(s => s.volumetric)
      .map(s => s.supplement || 0)
      .reduce((a, b) => a + b, 0)

    nonVolumetricSupplements = order.supplements
      .filter(s => !s.volumetric &&
        s.description !== 'reducedIva' &&
        s.description !== 'reducedIvaSecondHand' &&
        s.description !== 'reducedIvaBreakdown' &&
        s.description !== 'reducedIvaEU' &&
        s.description !== 'reducedIvaShipment' &&
        s.description !== 'refund' &&
        s.description !== 'refundStore' &&
        s.description !== 'refundByCancelation' &&
        s.description !== 'refundTransport' &&
        s.description !== 'transportToPeninsula' &&
        s.description !== 'transportSupplement' &&
        s.description !== 'unified' &&
        s.description !== 'igic' &&
        s.description !== 'shippingInsurancePrice' &&
        s.description !== 'priceChange')
      .map(s => s.supplement || 0)
      .reduce((a, b) => a + b, 0)

    refundTransportSupplements = order.supplements
      .filter(s => !s.volumetric && !s.deleted && (s.description === 'refundTransport' || s.description === 'transportToPeninsula' || s.description === 'transportSupplement'))
      .map(s => s.supplement || 0)
      .reduce((a, b) => a + b, 0)

    refundSupplements = order.supplements
      .filter(s => !s.volumetric && !s.deleted && (s.description === 'refund' || s.description === 'refundStore'))
      .map(s => s.supplement || 0)
      .reduce((a, b) => a + b, 0)

    // refundByCancelation is a special case, we don't need it in the summary

    orderFinalTotal += volumetricSupplements + nonVolumetricSupplements + refundTransportSupplements + refundSupplements
    shippingPrice += refundTransportSupplements

    if (order.status.id >= ADMITTED_STATUS || order.status_id === RETURN_STATUS) {
      invoiceButtonAction = order.invoice ? t('buttons.viewInvoice') : t('buttons.viewDraft')
    }
  }

  const initialTotal = (order?.syniva_price ?? 0) + (order?.shipping_insurance_price || 0) + shippingPrice + (order?.igic || 0) + ((order?.marketplace_id?.id === 0) ? order?.custom_order_price || 0 : 0)

  const { modalProps, show, close } = useModal()
  const { modalProps: modalPropsHistory, show: showHistory, close: closeHistory } = useModal()

  const [editAtypical, setEditAtypical] = useState(false)
  const [currentAtypical, setCurrentAtypical] = useState<number | undefined | null>(order?.atypical)
  const [editPackageNumber, setEditPackageNumber] = useState(false)
  const [currentPackageNumber, setCurrentPackageNumber] = useState<number>(order?.package_number)
  const [editLocation, setEditLocation] = useState(false)
  const [editEstimatedDate, setEditEstimatedDate] = useState(false)
  const [currentLocation, setCurrentLocation] = useState<string>(order?.location)
  const [currentEstimatedDate, setCurrentEstimatedDate] = useState<string | undefined | null>(order?.estimated_delivered_date)
  const [loading, setLoading] = useState<boolean>(false)

  const userEmail = supabaseClient.auth.user()?.email

  const handleEditAtypical = (isClose: boolean = false) => {
    if (isClose) {
      setCurrentAtypical(order?.atypical)
      setEditAtypical(false)
    } else {
      setEditAtypical(!editAtypical)
      setEditPackageNumber(false)
      setEditLocation(false)
      setEditEstimatedDate(false)
    }
  }

  const handleEditPackageNumber = (isClose: boolean = false) => {
    if (isClose) {
      setCurrentPackageNumber(order?.package_number)
      setEditPackageNumber(false)
    } else {
      setEditPackageNumber(!editPackageNumber)
      setEditLocation(false)
      setEditEstimatedDate(false)
      setEditAtypical(false)
    }
  }

  const handleEditLocation = (isClose: boolean = false) => {
    if (isClose) {
      setCurrentLocation(order?.location)
      setEditLocation(false)
    } else {
      setEditLocation(!editLocation)
      setEditPackageNumber(false)
      setEditEstimatedDate(false)
      setEditAtypical(false)
    }
  }

  const handleEstimatedDate = (isClose: boolean = false) => {
    if (isClose) {
      setCurrentEstimatedDate(order?.estimated_delivered_date)
      setEditEstimatedDate(false)
    } else {
      setEditEstimatedDate(!editLocation)
      setEditPackageNumber(false)
      setEditLocation(false)
      setEditAtypical(false)
    }
  }

  const onChangeAtypical = (value: number) => {
    setCurrentAtypical(value)
  }

  const onChangePackageNumber = (value: number) => {
    setCurrentPackageNumber(value)
  }

  const onChangeLocation = (value: any) => {
    setCurrentLocation(value)
  }

  const onChangeEstimatedDate = (value: any) => {
    if (value === null) {
      setCurrentEstimatedDate(null)
      handleSave()
    } else {
      const date = new Date(dayjs(value).toISOString())
      setCurrentEstimatedDate(date.toISOString())
    }
  }

  const handleSave = async () => {
    if (order?.id) {
      await supabaseClient.from('orders')
        .update({
          atypical: currentAtypical,
          location: (currentLocation === null || currentLocation === '' || currentLocation === undefined) ? null : currentLocation,
          package_number: currentPackageNumber,
          estimated_delivered_date: currentEstimatedDate,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', order?.id)
      updateOrderValue()
    }
    setEditPackageNumber(false)
    setEditLocation(false)
    setEditEstimatedDate(false)
    setEditAtypical(false)
  }

  const updateOrderComments = (comments: string) => {
    if (order) {
      const settedOrder = Object.assign(order)
      settedOrder.comments = (comments === '') ? null : comments
      order = Object.assign(settedOrder)
    }
  }

  const updateOrderValue = () => {
    if (order) {
      const settedOrder = Object.assign(order)
      settedOrder.package_number = currentPackageNumber
      settedOrder.location = currentLocation
      order = Object.assign(settedOrder)
    }
  }

  const handlePriority = async () => {
    if (order?.id) {
      setLoading(true)
      await supabaseClient.from('orders')
        .update({
          is_priority: !(order?.is_priority),
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', order?.id)
      props.refetchFn()
      setLoading(false)
    }
  }

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col xl={13} xs={24}>
          <Card>
            <EditButton onClick={() => editShow(id)} style={{ marginTop: 0, float: 'right' }}>
              {t('order.actions.editDetails')}
            </EditButton>
            <Descriptions title={t('order.details')} labelStyle={{ fontWeight: 'bold' }} column={1}>
              <Descriptions.Item label={t('order.fields.id')}>
                <div style={{ backgroundColor: `${(order?.is_priority) ? 'rgba(255, 215, 0, 0.19)' : 'transparent'}`, display: 'flex', alignItems: 'center', textAlign: 'center', gap: '1rem' }}>
                  {order?.id}
                  {order?.is_priority && <Icons.ExclamationOutlined style={{ fontSize: '20px', color: 'error' }} />}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={t('order.fields.alias')}>{getOrderIdAlias(order?.id || 0)}</Descriptions.Item>
              <Descriptions.Item label={t('order.fields.administrator')}>{order?.administrator?.name}</Descriptions.Item>
              <Descriptions.Item label={t('order.fields.status')}><Tag color={getColorLabel(order?.status?.name, order?.prev_status_id)}>{capitalizeFirstLetter(order?.status?.name)}</Tag></Descriptions.Item>
              <Descriptions.Item label={t('order.fields.type')}>{order?.type}</Descriptions.Item>
              <Descriptions.Item label={t('order.fields.paymentMethod')}>{order?.payment_method?.name}</Descriptions.Item>
              <Descriptions.Item label={t('order.fields.shipmentMethod')}>{order?.shipping_method?.name}</Descriptions.Item>
              {order?.estimated_costs_status && <Descriptions.Item label={t('order.fields.estimatedStatus')}>{MapEstimatedCostsStatusIDToText(order.estimated_costs_status)}</Descriptions.Item>}

              <Descriptions.Item label={t('order.fields.itemsCompleted')}>{itemsCompletedCounter(order?.items as any)}</Descriptions.Item>

              {(!editPackageNumber)
                ? <Descriptions.Item label={t('order.fields.package_number')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      {currentPackageNumber}
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 20 }} icon={<Icons.EditOutlined />} onClick={() => handleEditPackageNumber()} />
                    </div>
                  </Descriptions.Item>
                : <Descriptions.Item label={t('order.fields.package_number')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      <InputNumber style={{ minWidth: '50px', maxWidth: '100px', height: '32px', marginLeft: 10, marginTop: -5 }} defaultValue={currentPackageNumber} onChange={onChangePackageNumber}/>
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 10 }} icon={<Icons.SaveOutlined />} onClick={handleSave} />
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 0 }} icon={<Icons.CloseOutlined />} onClick={() => handleEditPackageNumber(true)} />
                    </div>
                  </Descriptions.Item>}
              {(!editLocation)
                ? <Descriptions.Item label={t('order.fields.location')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      {currentLocation}
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 20 }} icon={<Icons.EditOutlined />} onClick={() => handleEditLocation()} />
                      {order?.completed ? <b key={order.completed} style={{ marginBottom: '0px', marginLeft: '1rem' }}>{t('order.biReport.purchasesCompleted')}</b> : null}
                    </div>
                  </Descriptions.Item>
                : <Descriptions.Item label={t('order.fields.location')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      <Input style={{ minWidth: '50px', maxWidth: '100px', height: '32px', marginLeft: 10, marginTop: -5 }} defaultValue={currentLocation} onChange={(e) => onChangeLocation(e.target.value)}/>
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 10 }} icon={<Icons.SaveOutlined />} onClick={handleSave} />
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 0 }} icon={<Icons.CloseOutlined />} onClick={() => handleEditLocation(true)} />
                    </div>
                  </Descriptions.Item>}

              <Descriptions.Item label={t('order.fields.createdAt')}><DateField value={order?.created_at} format={DEFAULT_DATE_TIME_FORMAT} /></Descriptions.Item>
              <Descriptions.Item label={t('order.fields.updatedAt')}><DateField value={order?.updated_at} format={DEFAULT_DATE_TIME_FORMAT} /></Descriptions.Item>

              {(!editEstimatedDate)
                ? <Descriptions.Item label={t('order.fields.estimated_delivered_date')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      {currentEstimatedDate !== null ? <DateField value={currentEstimatedDate} format={DEFAULT_DATE_FORMAT} /> : ''}
                      <Button shape='circle' style={{ border: 'none', top: -5, width: '25px' }} icon={<Icons.EditOutlined />} onClick={() => handleEstimatedDate()} />
                      <Button shape='circle' style={{ border: 'none', top: -5, width: '25px' }} icon={<Icons.DeleteOutlined />} onClick={() => onChangeEstimatedDate(null)} />
                    </div>
                  </Descriptions.Item>
                : <Descriptions.Item label={t('order.fields.estimated_delivered_date')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      {/* <Input style={{ minWidth: '50px', maxWidth: '100px', height: '32px', marginLeft: 10, marginTop: -5 }} defaultValue={currentEstimatedDate} onChange={(e) => onChangeEstimatedDate(e.target.value)}/> */}
                      <DatePicker onChange={onChangeEstimatedDate} />
                      <Button shape='circle' style={{ border: 'none', top: -5 }} icon={<Icons.SaveOutlined />} onClick={handleSave} />
                      <Button shape='circle' style={{ border: 'none', top: -5 }} icon={<Icons.CloseOutlined />} onClick={() => handleEstimatedDate(true)} />
                    </div>
                  </Descriptions.Item>}
            </Descriptions>
            <Space style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={async () => {
                  if (order?.invoice_date && order?.invoice) {
                    const { data } = await supabaseClient.storage.from('invoices').list(undefined, { limit: 1, search: `${order?.invoice}` })

                    if (data?.length === 0) {
                      window.open(`${API_URL}/billings/pdf/${order?.id}`, '_blank', 'noopener,noreferrer')
                    } else {
                      const nowDate = new Date(Date.now())
                      const invDate = new Date(order?.invoice_date)
                      if (nowDate.getFullYear() === invDate.getFullYear()) {
                        if (nowDate.getMonth() + 1 > invDate.getMonth() + 1) {
                          window.open(`${API_URL}/billings/show/${order?.id}`, '_blank', 'noopener,noreferrer')
                        } else {
                          window.open(`${API_URL}/billings/pdf/${order?.id}`, '_blank', 'noopener,noreferrer')
                        }
                      } else {
                        window.open(`${API_URL}/billings/show/${order?.id}`, '_blank', 'noopener,noreferrer')
                      }
                    }
                  } else {
                    window.open(`${API_URL}/billings/pdf/${order?.id}`, '_blank', 'noopener,noreferrer')
                  }
                }}>
                  {invoiceButtonAction}
              </Button>
              <Button
                onClick={showHistory}>
                  {historicButtonAction}
              </Button>
            </Space>
            <Space style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <Button style={{ backgroundColor: `${(order?.issues?.id) ? ((order.issues.is_opened) ? '#f0e68c' : '#a9fd69') : 'transparent'}` }}
                onClick={() => {
                  if (order?.issues?.id) {
                    window.open(`/issues/show/${order.issues.id}`, '_blank', 'noopener,noreferrer')
                  } else {
                    createIssueShow()
                  }
                }}
              >
                {t(`issues.titles.${order?.issues?.id ? 'show' : 'create'}`)}
              </Button>
            </Space>
            <Space style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <Button loading={loading} onClick={() => handlePriority()}>
                {t(`order.actions.${(order?.is_priority) ? 'setNotPriority' : 'setIsPriority'}`)}
              </Button>
            </Space>
            {(userEmail && SUPERUSER_LIST.includes(userEmail) && order?.invoice_date && order?.invoice) && <Space style={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 0 0.5rem 0' }}>
              <Button style={{ backgroundColor: '#f0e68c' }}
                onClick={async () => {
                  window.open(`${API_URL}/billings/pdf/${order?.id}`, '_blank', 'noopener,noreferrer')
                }}>
                  {regenerateInvoiceAction}
              </Button>
            </Space>}

            <div style={{ height: '2rem' }} />

            <span style={{ cursor: 'pointer' }} onClick={show}>
              <Badge
                style={{ fontSize: 20 }}
                count={
                  <Icons.WarningOutlined style={{ color: order?.comments ? '#f5222d' : 'grey' }} />
                }
              />
            </span>
            <span
              style={{ cursor: 'pointer', float: 'right' }}
              onClick={() => {
                lockOrUnlockOrder(order?.id, !order?.locked)
                window.location.reload()
              }}
            >
              <Badge
                style={{ fontSize: 20 }}
                count={
                  order?.locked
                    ? <Icons.LockOutlined style={{ color: '#f5222d' }} />
                    : <Icons.UnlockOutlined style={{ color: '#21bf4b' }} />
                }
              />
            </span>
          </Card>
        </Col>

        <Col xl={11} xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>

              {(order?.cancelation_date)
                ? <Row justify='space-between' style={{ backgroundColor: '#f0e68c', borderRadius: '5px', margin: '-10px' }}>
                  <Typography style={{ padding: 10, fontWeight: 'bold' }}>{t('order.fields.cancelRequest')}:</Typography>
                  <div style={{ display: 'flex' }}>
                    <Col style={{ padding: 10 }}>
                      <Typography style={{ padding: 0, fontWeight: 'bold' }}>{t('order.fields.date')}:</Typography>
                      {(order?.cancelation_reason) && <Typography style={{ padding: 0, fontWeight: 'bold' }}>{t('order.fields.cancelReasonReturn')}:</Typography>}
                    </Col>
                    <Col style={{ padding: 10 }}>
                      <DateField value={order?.cancelation_date} format={DEFAULT_DATE_FORMAT} />
                      {(order?.cancelation_reason) && <Typography style={{ padding: 0 }}>{t(`order.fields.options.${order?.cancelation_reason}`)}</Typography>}
                    </Col>
                  </div>
                </Row>
                : null}

              {(order?.return_date)
                ? <Row justify='space-between' style={{ backgroundColor: '#f0e68c', borderRadius: '5px', margin: '-10px' }}>
                  <Typography style={{ padding: 10, fontWeight: 'bold' }}>{t('order.fields.returnRequest')}:</Typography>
                  <div style={{ display: 'flex' }}>
                    <Col style={{ padding: 10 }}>
                      <Typography style={{ padding: 0, fontWeight: 'bold' }}>{t('order.fields.date')}:</Typography>
                      {(order?.return_reason) && <Typography style={{ padding: 0, fontWeight: 'bold' }}>{t('order.fields.cancelReasonReturn')}:</Typography>}
                    </Col>
                    <Col style={{ padding: 10 }}>
                      <DateField value={order?.return_date} format={DEFAULT_DATE_FORMAT} />
                      {(order?.return_reason) && <Typography style={{ padding: 0 }}>{t(`order.fields.options.${order?.return_reason}`)}</Typography>}
                    </Col>
                  </div>
                </Row>
                : null}

            {(order?.cancelation_date || order?.return_date) ? <Divider style={{ margin: '10px 0px' }} /> : null}

            <Descriptions title={t('order.summary')} labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.subtotal')}>{currency(order?.subtotal)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.synivaPrice')}>{currency(order?.syniva_price)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.totalSaving')}>{currency(order?.total_saving)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.shippingPrice')}>{currency(shippingPrice)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.shippingInsurance')}>{currency(order?.shipping_insurance_price)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.igic')}>{currency(order?.igic)}</Descriptions.Item>
              {(order?.marketplace_id?.id === 0) && <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.customOrderPrice')}>{currency(order?.custom_order_price)}</Descriptions.Item>}
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.initial_total')}>{currency(initialTotal)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={`${t('order.fields.discount')} ${(order?.promotional_code) ? '(' + order?.promotional_code + ')' : ''}`} >
                {(order?.discount && order?.discount > 0) ? '-' : ''}{currency(order?.discount)}
              </Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.walletUsed')}>-{currency(order?.wallet_amount_used)}</Descriptions.Item>
            </Descriptions>

            <Divider style={{ margin: '10px 0px' }} />

            <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.supplements')}>{currency(nonVolumetricSupplements)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.volumetricSupplements')}>{currency(volumetricSupplements)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.supRefunds')}>{currency(refundSupplements)}</Descriptions.Item>
              <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.total')}>{currency(orderFinalTotal)}</Descriptions.Item>
            </Descriptions>

            {(order?.initial_payment) && <>
              <Divider style={{ margin: '10px 0px' }} />

              <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
                <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.initial_payment')}>{currency(order?.initial_payment)}</Descriptions.Item>
              </Descriptions>
            </>}

            {(order?.sequra_disbursement_ref) && <>
              <Divider style={{ margin: '10px 0px' }} />

              <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
                <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.disbursement')}>{order?.sequra_disbursement_ref}</Descriptions.Item>
              </Descriptions>
            </>}

            <div style={{ margin: '3rem 0px' }}>
              <Divider />

              {(!editAtypical)
                ? <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
                  <Descriptions.Item label={t('order.fields.atypical')}>
                      <div style={{ display: 'flex', alignItems: 'initial' }}>
                        {(currentAtypical) ? currency(currentAtypical) : ''}
                        <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 20 }} icon={<Icons.EditOutlined />} onClick={() => handleEditAtypical()} />
                      </div>
                  </Descriptions.Item>
                </Descriptions>
                : <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
                  <Descriptions.Item label={t('order.fields.atypical')}>
                    <div style={{ display: 'flex', alignItems: 'initial' }}>
                      <InputNumber decimalSeparator=',' style={{ minWidth: '50px', maxWidth: '100px', height: '32px', marginLeft: 10, marginTop: -5 }} defaultValue={currentAtypical || undefined} onChange={onChangeAtypical}/>
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 10 }} icon={<Icons.SaveOutlined />} onClick={handleSave} />
                      <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 0 }} icon={<Icons.CloseOutlined />} onClick={() => handleEditAtypical(true)} />
                    </div>
                  </Descriptions.Item>
                </Descriptions>}

              {(order?.decrease_costs) && <>
                <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
                  <Descriptions.Item style={{ padding: 0 }} label={t('decrease.decrease_costs')}>{currency(order?.decrease_costs)}</Descriptions.Item>
                </Descriptions>
                <Descriptions labelStyle={{ fontWeight: 'bold' }} contentStyle={{ position: 'absolute', right: 15 }} column={1}>
                  <Descriptions.Item style={{ padding: 0 }} label={t('decrease.decrease_types')}>{''}</Descriptions.Item>
                </Descriptions>
                <ul style={{ paddingLeft: '-1rem' }}>
                  <li>{t(`decrease_types.${(DECREASE_TYPES_MAPPER as any)[order?.decrease_types as number]}`)}</li>
                </ul>
              </>}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => editdecreaseShow() }>
                  {t('decrease.decrease_button')}
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <EditOrderDetails
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        closeDrawer={editClose}
        order={order}
      />
      <CommentModal
        modalProps={modalProps}
        close={close}
        orderId={order?.id || 0}
        comment={order?.comments || ''}
        updateOrderValue={updateOrderComments}
      />
      <HistoryModal
        modalProps={modalPropsHistory}
        close={closeHistory}
        orderId={order?.id || 0}
      />
      <IssueDrawer
        drawerProps={createIssueDrawerProps}
        formProps={createIssueFormProps}
        close={createIssueClose}
        orderId={order?.id}
        refetchFn={props.refetchFn}
      />
      <DecreaseDrawer
        drawerProps={editdecreaseDrawerProps}
        formProps={editdecreaseFormProps}
        close={editdecreaseClose}
        orderId={order?.id}
      />
    </>
  )
}
