export const formatDateTime = (datetime: string | undefined, onlyDate: boolean = false): string => {
  if (datetime) {
    const dateObj = new Date(datetime)
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1
    const year = dateObj.getFullYear()
    const hours = dateObj.getHours()
    const mins = dateObj.getMinutes()

    const parsedHours = hours >= 10 ? hours : '0' + hours
    const parsedMins = mins >= 10 ? mins : '0' + mins

    if (onlyDate) return `${day}/${month}/${year}`

    return `${day}/${month}/${year} ${parsedHours}:${parsedMins}`
  } else {
    return ''
  }
}

export const formatTime = (datetime: string | undefined): string => {
  if (datetime) {
    const dateObj = new Date(datetime)
    const hours = dateObj.getHours()
    const mins = dateObj.getMinutes()

    const parsedHours = hours >= 10 ? hours : '0' + hours
    const parsedMins = mins >= 10 ? mins : '0' + mins

    return `${parsedHours}:${parsedMins}`
  } else {
    return ''
  }
}

export const processDate = (dateString: string): Date => {
  const date = new Date(dateString)
  date.setHours(date.getHours() + 5)
  return date
}

export const excelDateFormat = (datetime: string | undefined): Date | string => {
  if (datetime) {
    const dateObj = new Date(datetime)

    return dateObj
  } else {
    return ''
  }
}
