import { Avatar, Popover } from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { useEffect, useState } from 'react'
import { getLastEntryData, upsertEmployeeTime } from 'utility'
import { UserMenuContent } from './UserMenuContent'

type UserMenuProps = {
  user: any
}

const UserMenu: React.FC<UserMenuProps> = ({ user }) => {
  const [entryData, setEntryData] = useState<definitions['employee_times'] | null>(null)
  const [note, setNote] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (user?.user_metadata?.id) getEmployeeLastTime()
  }, [user])

  const getEmployeeLastTime = async () => {
    const data = await getLastEntryData(user?.user_metadata?.id)
    if (data) {
      setEntryData(data)
    }
  }

  const registryEmployeeData = async () => {
    let data: any
    if (entryData && entryData.date_start) {
      data = await upsertEmployeeTime(user?.user_metadata?.id, new Date(entryData?.date_start), note, entryData.id)
    } else {
      data = await upsertEmployeeTime(user?.user_metadata?.id)
    }
    if (data) {
      if (data.date_end) setEntryData(null)
      else setEntryData(data)
    }
  }

  return <Popover placement="bottomRight" content={UserMenuContent({ user, registryEmployeeData, entryData, note, setNote, showProfileLink: true })}>
    <Avatar size="large" src={user?.user_metadata?.avatar_url} alt={user?.name} />
  </Popover>
}

export default UserMenu
