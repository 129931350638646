import { Button, Drawer, Grid, Icons, Input, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { read, utils, writeFile } from 'xlsx'
import { definitions } from 'interfaces'
import { ExcelDateToJSDate, PURCHASE_CONSOLIDATED_TEMPLATE_PATH, getIvaOfAmount, getOrderIdByAlias, processDate, supabaseClient } from 'utility'

type rowType = {
  invoiceNumber: string,
  idPurchase: string,
  alias: string,
  purchaseAmount: number,
  ivaAmount: string,
  invoiceDate: string,
  cif: string,
  legalName: string,
  legalAddress: string,
  province: string,
  postalCode: string
}

export const UpdateConsolidatedButton = () => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const {
    drawerProps: createDrawerProps,
    show: createShow
  } = useDrawerForm<definitions['orders']>({
    action: 'create',
    redirect: false
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [updateFile, setUpdateFile] = useState(null)
  const [updateFinished, setUpdateFinished] = useState<string | null>(null)

  const handleFile = (e: any) => {
    setUpdateFile(e.target.files[0])
  }

  const updatePurchases = async (row: rowType) => {
    const { data: marketIds, error: errorMarketsIds } = await supabaseClient.from('market_orders')
      .select('id')
      .eq('deleted', false)
      .eq('order_id', getOrderIdByAlias(row.alias))
      .eq('purchase_id', row.idPurchase)

    if (errorMarketsIds) {
      console.log(errorMarketsIds)
      setError(true)
    }

    if (marketIds) {
      const invDate = new Date(row.invoiceDate)
      invDate.setHours(8)

      let supplier: any = null
      const { data: marketplace, error: markeplaceSupplierError } = await supabaseClient.from('suppliers')
        .select('id, cif, legal_name, legal_address, province, postal_code')
        .ilike('cif', `%${row.cif}%`)
        .eq('deleted', false)
        .order('created_at', { ascending: false })
        .single()

      if (markeplaceSupplierError) {
        await supabaseClient.from('suppliers')
          .insert({
            cif: row.cif,
            legal_name: row.legalName,
            legal_address: row.legalAddress,
            province: row.province,
            postal_code: row.postalCode,
            verified: true
          })

        supplier = {
          cif: row.cif,
          legal_name: row.legalName,
          legal_address: row.legalAddress,
          province: row.province,
          postal_code: row.postalCode
        }
      }

      if (marketplace) {
        supplier = marketplace
      }

      await supabaseClient.from('market_orders')
        .update({
          invoice_number: row.invoiceNumber,
          purchase_amount: row.purchaseAmount,
          iva_type: isNaN(parseFloat(row.ivaAmount)) ? 'ISP' : parseFloat(row.ivaAmount),
          iva_amount: row.purchaseAmount - parseFloat(getIvaOfAmount(row.purchaseAmount, isNaN(parseFloat(row.ivaAmount)) ? 0 : parseFloat(row.ivaAmount)).toFixed(2)),
          is_purchase: (row.purchaseAmount >= 0),
          invoice_date: invDate.toISOString(),
          marketplace_legal_name: supplier.legal_name || '',
          marketplace_legal_address: supplier.legal_address || '',
          marketplace_cif: supplier.cif || '',
          marketplace_province: supplier.province || '',
          marketplace_postal_code: supplier.postal_code || '',
          updated_by: supabaseClient.auth.user()?.email
        })
        .in('id', marketIds.map((mo: { id: number }) => mo.id))
        .eq('purchase_amount', row.purchaseAmount)
    }
  }

  const insertPurchases = async (row: rowType) => {
    const invDate = new Date(row.invoiceDate)
    invDate.setHours(8)

    let supplier: any = null
    if (row.cif !== '') {
      const { data: marketplace, error: markeplaceSupplierError } = await supabaseClient.from('suppliers')
        .select('id, cif, legal_name, legal_address, province, postal_code')
        .ilike('cif', `%${row.cif}%`)
        .eq('deleted', false)
        .order('created_at', { ascending: false })
        .single()

      if (markeplaceSupplierError) {
        await supabaseClient.from('suppliers')
          .insert({
            cif: row.cif,
            legal_name: row.legalName,
            legal_address: row.legalAddress,
            province: row.province,
            postal_code: row.postalCode,
            verified: true
          })

        supplier = {
          cif: row.cif,
          legal_name: row.legalName,
          legal_address: row.legalAddress,
          province: row.province,
          postal_code: row.postalCode
        }
      }

      if (marketplace) {
        supplier = marketplace
      }
    }

    await supabaseClient.from('market_orders')
      .insert({
        purchase_id: row.idPurchase,
        order_id: getOrderIdByAlias(row.alias),
        invoice_number: row.invoiceNumber,
        purchase_amount: row.purchaseAmount,
        iva_type: isNaN(parseFloat(row.ivaAmount)) ? 'ISP' : parseFloat(row.ivaAmount),
        iva_amount: row.purchaseAmount - parseFloat(getIvaOfAmount(row.purchaseAmount, isNaN(parseFloat(row.ivaAmount)) ? 0 : parseFloat(row.ivaAmount)).toFixed(2)),
        is_purchase: (row.purchaseAmount >= 0),
        invoice_date: invDate.toISOString(),
        marketplace_legal_name: supplier.legal_name || '',
        marketplace_legal_address: supplier.legal_address || '',
        marketplace_cif: supplier.cif || '',
        marketplace_province: supplier.province || '',
        marketplace_postal_code: supplier.postal_code || '',
        updated_by: supabaseClient.auth.user()?.email
      })
  }

  const updatePurchasesExcel = async (e: any) => {
    e.preventDefault()
    setError(false)
    setLoading(true)
    setUpdateFinished(null)

    const reader = new FileReader()
    reader.onload = async function (e) {
      try {
        const data = e?.target?.result
        const readedData = read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        const dataParse: any = utils.sheet_to_json(ws, { header: 1 })

        const orderIdsList: number[] = []
        const excelOrders: rowType[] = []
        const orderReadyList: string[] = []

        dataParse.forEach((r: any, index: number) => {
          if (index > 0) {
            const id = getOrderIdByAlias(String(r[7]))
            if (!orderIdsList.includes(id)) {
              orderIdsList.push(id)
            }

            excelOrders.push({
              invoiceDate: r[0],
              invoiceNumber: String(r[1]),
              purchaseAmount: Number(r[4]),
              idPurchase: String(r[6]),
              alias: r[7],
              ivaAmount: r[17] ? String(r[17]) : 'ISP',
              cif: r[18] || '',
              legalName: r[19] || '',
              legalAddress: r[20] || '',
              province: r[21] || '',
              postalCode: r[22] || ''
            })
          }
        })

        const { data: orderMarketsInternal } = await supabaseClient.from('market_orders')
          .select('id, order_id, purchase_id, invoice_number, purchase_amount')
          .in('order_id', orderIdsList)
          .eq('deleted', false)

        for (const eo of excelOrders) {
          const orderPurchasesInternal = orderMarketsInternal?.filter((p: any) => p.order_id === getOrderIdByAlias(eo.alias))
          const orderPurchasesExcel = excelOrders?.filter((p: any) => p.alias === eo.alias)

          if (!orderReadyList.includes(eo.alias)) {
            orderReadyList.push(eo.alias)

            // console.log('Internal -> ', orderPurchasesInternal)
            // console.log('Excel -> ', orderPurchasesExcel)
            for (const eop of orderPurchasesExcel) {
              try {
                if (typeof eop.invoiceDate === 'number') {
                  const dateExcel = ExcelDateToJSDate(eop.invoiceDate)
                  eop.invoiceDate = dateExcel.toISOString()
                } else {
                  const dateSplitted = eop.invoiceDate.split('/')
                  eop.invoiceDate = `${dateSplitted[1]}/${dateSplitted[0]}/${dateSplitted[2]}`
                }

                if (orderPurchasesInternal?.find((o: any) => o.purchase_id === eop.idPurchase && o.purchase_amount === eop.purchaseAmount)) {
                  console.log('Update market order -> ', eop)
                  await updatePurchases(eop)
                } else {
                  console.log('Insert market order -> ', eop)
                  await insertPurchases(eop)
                }
              } catch (err) {
                console.log(err)
              }
            }
          }
        }

        const superIdArray = []

        const accumulated = orderReadyList
        while (accumulated.length) {
          const subArray = accumulated.splice(0, 50)
          superIdArray.push(subArray)
        }

        let finalDataUpdate: any[] = []
        for (const arr of superIdArray) {
          const { data: dataUpdated } = await supabaseClient.from('market_orders')
            .select('*, orders(id, alias)')
            .in('order_id', arr.map((mo: string) => getOrderIdByAlias(mo)))
            .eq('deleted', false)

          if (dataUpdated) {
            finalDataUpdate = [...finalDataUpdate, ...dataUpdated]
          }
        }

        await downloadUpdatedOrders(finalDataUpdate || [])

        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('ok')
      } catch (err) {
        setError(true)
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('error')
      }
    }

    if (updateFile) {
      reader.readAsBinaryString(updateFile)
    }
  }

  const downloadUpdatedOrders = (data: any[]) => {
    const headers = {
      invoiceDate: t('purchases.fields.invoiceDate'),
      purchaseId: t('purchases.fields.purchaseId'),
      invoiceNumber: t('purchases.fields.invoiceNumber'),
      trackingId: t('purchases.fields.trackingId'),
      type: t('market_order.fields.type'),
      id: t('order.fields.id'),
      alias: t('order.fields.alias'),
      marketplace: t('purchases.fields.marketplace'),
      cif: t('purchases.fields.cif'),
      province: t('purchases.fields.province'),
      postalCode: t('purchases.fields.postalCode'),
      baseAmount: t('purchases.fields.baseAmount'),
      ivaType: t('purchases.fields.ivaType'),
      ivaAmount: t('purchases.fields.ivaAmount'),
      totalInvoice: t('purchases.fields.totalInvoice')
    }

    const rowList = data.map((x: any) => {
      const row = {
        invoiceDate: x?.invoice_date ? processDate(x?.invoice_date) : '',
        purchaseId: x?.purchase_id || '',
        invoiceNumber: x?.invoice_number || '',
        trackingId: x?.tracking_id || '',
        type: x.is_purchase ? t('market_order.fields.purchase') : t('market_order.fields.refund'),
        id: (x?.orders as any)?.id || '',
        alias: (x?.orders as any)?.alias || '',
        marketplace: x?.marketplace_legal_name || '',
        cif: x?.marketplace_cif || '',
        province: x?.marketplace_province || '',
        postalCode: x?.marketplace_postal_code || '',
        baseAmount: ((x.purchase_amount || 0) - (x?.iva_amount || 0)) || '',
        ivaType: x?.iva_type || '',
        ivaAmount: x?.iva_amount || 0,
        totalInvoice: x.purchase_amount || 0
      }
      return row
    })

    rowList.unshift(headers)

    const worksheet = utils.json_to_sheet(rowList, { skipHeader: true })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    writeFile(workbook, 'amazon_consolidated_purchases_updated.xlsx')
  }

  return <>
    <Button type="primary" onClick={() => createShow()}>
      {t('purchases.amazonConsolidated.title')}
    </Button>
    <Drawer
      {...createDrawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('purchases.amazonConsolidated.title')}
        </Typography.Text>

        <a href={PURCHASE_CONSOLIDATED_TEMPLATE_PATH} download="purchases-consolidated-template.xlsx">
          <Button>
            <Icons.DownloadOutlined />
            {t('order.biReport.labelTemplate')}
          </Button>
        </a>

        <Typography.Text>{t('marketplace.actions.uploadExcel')}</Typography.Text>
        <Input
          accept='.xlsx'
          bordered={false}
          size='middle'
          type='file'
          key='exceluploader'
          onChange={handleFile}
        />
        <Button disabled={updateFile === null} loading={loading} type='primary'
          onClick={(e: any) => updatePurchasesExcel(e)}
        >
          {t('purchases.amazonConsolidated.updatePurchases')}
        </Button>

        {loading && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('order.biReport.labelUpdating')}
        </Typography>}
        {updateFinished && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t(`order.biReport.labelFinish${updateFinished}`)}
        </Typography>}

      </Space>

      {(error)
        ? <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
          <Typography.Text>
            {t('purchases.amazonConsolidated.error')}
          </Typography.Text>
        </Space>
        : null}
    </Drawer>
  </>
}
