import { useTranslate, IResourceComponentsProps, useSelect as useSelectCore } from '@pankod/refine-core'
import {
  List,
  Table,
  Form,
  Space,
  Button,
  SaveButton,
  EditButton,
  useEditableTable,
  FilterDropdownProps,
  Select,
  DatePicker,
  useModal,
  Icons
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { SelectFilterDropdown } from 'components/filterDropdown'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { ADMIN_ROLE_ID, supabaseClient } from 'utility'
import { EmployeeRequestModal } from './employeeRequestModal'

export const EmployeeList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  if (supabaseClient.auth.user()?.user_metadata?.role_id !== ADMIN_ROLE_ID) return null

  const {
    tableProps,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps
  } = useEditableTable<definitions['users']>({
    resource: 'users',
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'role_id',
        operator: 'in',
        value: [2, 3, 4]
      }
    ],
    metaData: {
      select: '*'
    }
  })

  const { options: rolesOptions } = useSelectCore<definitions['roles']>({
    resource: 'roles',
    fetchSize: 10,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const {
    modalProps: modalPropsRequestModal,
    show: showRequestModal,
    close: closeRequestModal
  } = useModal()

  const [dateStart, setDateStart] = useState<any>(dayjs(new Date(Date.now())))
  const [dateEnd, setDateEnd] = useState<any>(dayjs(new Date(Date.now())))
  const [absentRequest, setAbsentRequest] = useState<definitions['employee_absent'][]>([])

  const exportEmployeeTimes: any = async () => {
    try {
      const { data, error } = await supabaseClient.from<definitions['employee_times']>('employee_times')
        .select('id, created_at, user_id(id, email), date_start, date_end, total, note')
        .gte('date_start', dateStart.toISOString().split('T')[0])
        .lte('date_end', dateEnd.toISOString().split('T')[0])
        .eq('deleted', false)
        .order('date_start', { ascending: true })

      if (error) throw new Error(error.message)

      data.forEach((item: any) => {
        const hours = Math.floor(item.total / (1000 * 60 * 60))
        const minutes = Math.floor((item.total % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((item.total % (1000 * 60)) / 1000)
        item.total = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
        item.user = item.user_id.email
        item.date_start = new Date(item.date_start).toISOString().split('T')[0]
        item.date_end = new Date(item.date_end).toISOString().split('T')[0]
        delete item.user_id
      })

      return data
    } catch (error) {
      console.log(error)
    }
  }

  const exportEmployeeAbsents: any = async () => {
    try {
      const { data, error } = await supabaseClient.from<definitions['employee_absent']>('employee_absent')
        .select('id, created_at, user_id(id, email), type, status, date_start, date_end, hours, reason')
        .gte('date_start', `${new Date(Date.now()).getFullYear()}-01-01`)
        .eq('deleted', false)
        .order('date_start', { ascending: true })

      if (error) throw new Error(error.message)

      data.forEach((item: any) => {
        item.type = t(`profile.form.${item.type}`)
        item.email = item.user_id.email
        item.date_start = new Date(item.date_start).toISOString().split('T')[0]
        item.date_end = (item.date_end === null) ? '' : new Date(item.date_end).toISOString().split('T')[0]
        delete item.user_id
      })

      return data
    } catch (error) {
      console.log(error)
    }
  }

  const getPendingEmployeeRequest = async () => {
    const { data, error } = await supabaseClient.from<definitions['employee_absent']>('employee_absent')
      .select('*, user_id(id, email)')
      .eq('status', 'pending')
      .eq('deleted', false)

    if (error) return

    setAbsentRequest(data)
  }

  const approveRejectEmployeeRequest = async (item: definitions['employee_absent'], status: string) => {
    const { error } = await supabaseClient.from<definitions['employee_absent']>('employee_absent')
      .update({
        updated_at: new Date(Date.now()).toISOString(),
        approved_by: supabaseClient.auth.user()?.user_metadata.id || undefined,
        status: status
      })
      .eq('id', item.id)
      .eq('deleted', false)
      .eq('status', 'pending')

    if (error) {
      console.log(error)
      return null
    }
  }

  useEffect(() => {
    getPendingEmployeeRequest()
  }, [])

  return (
    <List breadcrumb={false} title={t('menu.employees')}
      headerProps={{
        extra: <>
          <Button key={absentRequest.length} disabled={!(absentRequest.length > 0)} onClick={() => showRequestModal()}>
            {(absentRequest.length > 0) ? <Icons.ExclamationOutlined /> : null}
            {t('employee_absent.approveModal.buttonText')}
          </Button>
          <ExportToExcelButton exportButtonName={t('employee_absent.exportAbsent')} filename='employees' asyncFunction={() => exportEmployeeAbsents()} style={{ marginRight: '2rem' }} />
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <DatePicker
              style={{ width: '50%' }}
              placeholder={t('employee_absent.fields.dateStart')}
              onChange={(value: any) => {
                setDateStart(value)
              }
            } />
            <DatePicker
              style={{ width: '50%' }}
              placeholder={t('employee_absent.fields.dateEnd')}
              onChange={(value: any) => {
                setDateEnd(value)
              }
            } />
          </div>
          <ExportToExcelButton filename='employees' asyncFunction={() => exportEmployeeTimes()} />
        </>
      }}
    >
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            dataIndex="id"
            title='ID'
            align='center'
            sorter
          />
          <Table.Column<definitions['users']>
            key="id"
            dataIndex="email"
            title={t('users.email')}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='users'
                optionLabel='email'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            )}
          />
          <Table.Column
            dataIndex="name"
            title={t('users.name')}
          />
          <Table.Column<definitions['users']>
            dataIndex='role_id'
            key="role_id"
            title={t('users.role')}
            className='role-id'
            render={(value, data: definitions['users']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name='role_id'
                    style={{ margin: 0 }}
                  >
                    <Select options={rolesOptions} />
                  </Form.Item>
                )
              }
              return rolesOptions.filter(role => role.value === value)[0]?.label
            }}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='roles'
                optionLabel='name'
                optionValue='id'
                sorters={[{
                  field: 'id',
                  order: 'asc'
                }]}
              />
            )}
          />
          <Table.Column
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record: any) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }
              return (
                <>
                  <Space>
                    <EditButton
                      {...editButtonProps(record.id)}
                      hideText
                      size="small"
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
      <EmployeeRequestModal
        modalProps={modalPropsRequestModal}
        close={closeRequestModal}
        requests={absentRequest}
        approveRejectRequest={approveRejectEmployeeRequest}
        getPendingEmployeeRequest={getPendingEmployeeRequest}
      />
    </List>
  )
}
