import { useTranslate } from '@pankod/refine-core'
import { Modal, ModalProps, Switch, Typography, Table, Collapse } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { EMPTY_TEXT, EXCLUDED_KEYS, formatDateTime, supabaseClient } from 'utility'
import { ORDER_STATUS_MAPPER, SHIPMENT_METHOD_MAPPER } from 'utility/mapper'
import { OrderStatusId, ShipmentMethodId } from 'types/types'

type HistoryModalProps = {
    modalProps: ModalProps,
    close: () => void,
    orderId: number
}
type Record = { [key: string]: any }
type RecordChanges = { 'old_value': any, 'new_value': any }
type ModifiedRecord = { [key: string]: RecordChanges }

export const HistoryModal: React.FC<HistoryModalProps> = ({ modalProps, close, orderId }) => {
  const t = useTranslate()
  const [onlyStates, setOnlyStates] = useState(true)
  const [items, setItems] = useState([{}])
  const [allItems, setAllItems] = useState() as any
  const [processedItems, setProcessedItems] = useState() as any

  const handleOk = () => {
    close()
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    loadData()
  }, [onlyStates])

  const loadData = async () => {
    const auditArrayPromises = []

    auditArrayPromises.push(supabaseClient.from('audit_orders').select('id, created_at, operation, record, resource_id').order('created_at', { ascending: true }).eq('resource_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_items').select('id, created_at, operation, record, resource_id, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_market_orders').select('id, created_at, operation, resource_id, record, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_supplements').select('id, created_at, operation, resource_id, record, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_refunds').select('id, created_at, operation, record, resource_id, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))

    const data = await Promise.all(auditArrayPromises)

    let arrayOfChanges: any[] = []

    if (data && data.length > 0) {
      const orders = (data[0] && data[0].data) ? data[0].data : []
      const items = (data[1] && data[1].data) ? data[1].data : []
      const marketOrders = (data[2] && data[2].data) ? data[2].data : []
      const supplements = (data[3] && data[3].data) ? data[3].data : []
      const refunds = (data[4] && data[4].data) ? data[4].data : []
      const statusChanges: any[] = []

      // items = items?.map((i) => Object.assign({}, i, { table: 'items' }))

      items.forEach((i, index) => {
        const record = Object.assign({}, i, { table: 'items' })
        if (index > 0) {
          record.old_record = items
            .filter((item) => i.resource_id === item.resource_id)
            .sort((prev, next) => next.id - prev.id)
            .find((item) => i.id > item.id)?.record
        } else {
          record.old_record = items[index].record
          i.operation = 'INSERT'
        }
        i.record = record.record
        i.old_record = record.old_record
        i.table = 'items'
      })

      // marketOrders = marketOrders?.map((mo) => Object.assign({}, mo, { table: 'market_orders' }))

      marketOrders.forEach((mo, index) => {
        const record = Object.assign({}, mo, { table: 'market_orders' })
        if (index > 0) {
          record.old_record = marketOrders
            .filter((item) => mo.resource_id === item.resource_id)
            .sort((prev, next) => next.id - prev.id)
            .find((item) => mo.id > item.id)?.record
        }
        mo.record = record.record
        mo.old_record = record.old_record
        mo.table = 'market_orders'
      })

      // supplements = supplements?.map((s) => Object.assign({}, s, { table: 'supplements' }))

      supplements?.forEach((s, index) => {
        const record = Object.assign({}, s, { table: 'supplements' })
        if (index > 0) {
          record.old_record = supplements
            .filter((item) => s.resource_id === item.resource_id)
            .sort((prev, next) => next.id - prev.id)
            .find((item) => s.id > item.id)?.record
        }
        s.record = record.record
        s.old_record = record.old_record
        s.table = 'supplements'
      })

      // refunds = refunds?.map((r) => Object.assign({}, r, { table: 'refunds' }))

      refunds?.forEach((r, index) => {
        const record = Object.assign({}, r, { table: 'refunds' })
        if (index > 0) {
          record.old_record = refunds
            .filter((item) => r.resource_id === item.resource_id)
            .sort((prev, next) => next.id - prev.id)
            .find((item) => r.id > item.id)?.record
        }
        r.record = record.record
        r.old_record = record.old_record
        r.table = 'refunds'
      })

      orders?.forEach((o, index) => {
        if (o.operation === 'INSERT') statusChanges.push({ ...o, table: 'orders' })

        const record = Object.assign({}, o, { table: 'orders' })
        if (index > 0) {
          record.old_record = orders[index - 1].record
          statusChanges.push(record)
        }
      })

      // Merge and order by created_at
      arrayOfChanges = arrayOfChanges.concat(items, marketOrders, supplements, refunds, statusChanges)
      arrayOfChanges.sort((prev, next) => Date.parse(prev.created_at) - Date.parse(next.created_at))
      setAllItems(arrayOfChanges)
      if (onlyStates) {
        const onlyStateArray = arrayOfChanges.filter(x => x.table === 'orders')
        setItems(onlyStateArray)
      } else {
        // remove duplicated values from array
        const uniqueArray = arrayOfChanges.filter((value: any, index: any) => {
          const _value = JSON.stringify(value)
          return index === arrayOfChanges.findIndex((arrayOfChanges: any) => {
            return JSON.stringify(arrayOfChanges) === _value
          })
        })
        setItems(uniqueArray)
      }
    }
  }

  function findDifferences (prev: Record, curr: Record) {
    // console.log(prev.status_id, curr.status_id)
    const diff: ModifiedRecord = {}
    Object.keys(prev).forEach(key => {
      if (prev[key] !== curr[key] && (onlyStates === true ? ['status_id'].includes(key) : !['updated_by', 'updated_at', 'created_at', 'purchase_conditions', 'decrease_types', 'prev_status_id', 'id', 'emails_id', 'read_by_user', 'email_sent_at', 'bank_reference', 'code', 'parent', 'image', 'device', 'locked', 'user_id', 'completed', 'invoice_number', 'marketplace_id', 'payment_method_id', 'billing_address_id', 'shipping_address_id', 'order_id', 'item_category_id', 'is_purchase', 'purchase_parent'].includes(key))) {
        if (key !== 'updated_by' && (curr[key] === null || curr[key] === undefined)) return null
        diff[key] = {
          old_value: ['estimated_delivered_date'].includes(key) ? formatDateTime(prev[key]) : prev[key] === undefined || prev[key] === null ? EMPTY_TEXT : prev[key],
          new_value: ['estimated_delivered_date'].includes(key) ? formatDateTime(curr[key]) : curr[key]
        }
      }
    })
    return diff
  }

  function processChanges (data: any) {
    const results: any = []

    data.forEach((audit: any, index: any) => {
      // console.log(audit, data)
      if (audit.operation === 'UPDATE') {
        // const nextAudit = data.find((item: any) => item.resource_id === audit.resource_id && audit.id < item.id)
        // const nextAudit = data.filter((item: any) => item.resource_id === audit.resource_id).find((item: any) => audit.id < item.id)
        // console.log(nextAudit?.id, nextAudit?.record?.status_id, audit?.id, audit?.record?.status_id, index)
        // console.log(nextAudit?.id, nextAudit?.record?.status_id, audit?.id, audit?.record?.status_id, index)
        if (audit.old_record) {
          const diff = findDifferences(audit.old_record as Record, audit.record as Record)
          // console.log(diff)
          if (Object.keys(diff).length === 1 && Object.keys(diff)[0] === 'updated_by') return

          if (Object.keys(diff).length > 0) {
            results.push({
              ...audit,
              modifiedRecord: diff
            })
          }
        } else {
          // console.log(audit, 'new record found')
          // if (!onlyStates) {
          //   results.push({
          //     ...audit,
          //     modifiedRecord: {},
          //     insertedResource: true
          //   })
          // }
        }
      } else if (audit.operation === 'INSERT') {
        if (!onlyStates) {
          results.push({
            ...audit,
            modifiedRecord: {},
            insertedResource: true
          })
        }
      }
    })

    return results
  }

  const onChange = (checked: boolean) => {
    if (checked) {
      const onlyStateArray = allItems.filter((x: any) => x.table === 'orders')
      setItems(onlyStateArray)
      setOnlyStates(true)
    } else {
      setItems(allItems)
      setOnlyStates(false)
    }
  }

  useEffect(() => {
    setProcessedItems(processChanges(items))
  }, [items])

  const renderUpdatedByColunm = (itemIndex: number) => {
    let userEmail = ''
    if (processedItems) {
      userEmail = processedItems[itemIndex].record.updated_by || processedItems[itemIndex].record.user_email || ''
      // if ((processedItems[itemIndex].record.updated_by === null || processedItems[itemIndex].record.updated_by === undefined) && itemIndex > 0) {
      //   userEmail = processedItems?.find((item: any, index: number) => item.record.updated_by !== null && item.record.updated_by !== undefined && index > itemIndex)?.record?.updated_by || null
      // } else if ((processedItems[itemIndex].record.updated_by === null || processedItems[itemIndex].record.updated_by === undefined) && itemIndex === 0) {
      //   userEmail = processedItems?.find((item: any, index: number) => item.record.updated_by !== null && item.record.updated_by !== undefined && index > itemIndex)?.record?.updated_by || null
      // } else if (processedItems[itemIndex].record.updated_by) {
      //   userEmail = processedItems?.find((item: any, index: number) => item.record.updated_by !== null && item.record.updated_by !== undefined && index > itemIndex)?.record?.updated_by || null
      // }
    }
    return `${t('order.historic.sistem')} - (${userEmail})`
  }

  const renderHistory = (key: string, changes: any) => {
    if (changes.old_value !== changes.new_value) {
      let oldText = ''
      let newText = ''

      if (key === 'status_id') {
        oldText = ORDER_STATUS_MAPPER[changes.old_value as OrderStatusId]
        newText = ORDER_STATUS_MAPPER[changes.new_value as OrderStatusId]
      } else if (key === 'shipping_method_id') {
        oldText = SHIPMENT_METHOD_MAPPER[changes.old_value as ShipmentMethodId]
        newText = SHIPMENT_METHOD_MAPPER[changes.new_value as ShipmentMethodId]
      } else if (key === 'description') {
        oldText = changes.old_value === 'shippingInsurancePrice' ? t('order.historic.shippingInsurancePrice') : t(`${changes.old_value}`)
        newText = changes.new_value === 'shippingInsurancePrice' ? t('order.historic.shippingInsurancePrice') : t(`${changes.new_value}`)
      } else if (key === 'invoice_file') {
        newText = changes.new_value[0].name || t('order.historic.newFile')
      } else if (key === 'volumetricInfo') {
        oldText = JSON.stringify(changes.old_value)
        newText = JSON.stringify(changes.new_value)
      } else {
        oldText = t(`${changes.old_value}`)
        newText = t(`${changes.new_value}`)
      }

      // console.log(oldText, newText, changes, key)
      return (
        <>
          <span style={{ color: 'red', wordBreak: 'break-word' }}>{oldText}</span>
          <b> {' -> '} </b>
          <span style={{ color: 'green', wordBreak: 'break-word' }}>{newText}</span>
        </>
      )
    } else {
      return null
    }
  }

  const renderNewResource = (value: any, index: number) => {
    const { Panel } = Collapse

    return (
      <>
        <Collapse>
          <Panel header={t('order.historic.insertedResource', { resource: value.table, resourceId: value.resource_id })} key={index}>
            {Object.entries(value.record).map(([key, value]) => {
              if (value === null || value === undefined) return null
              else if (EXCLUDED_KEYS.includes(key)) return null
              return <div key={`${index}-${key}`}>
                <b>{t(`order.historic.${key}`)}</b>
                {': '}
                {(key === 'status_id') ? ORDER_STATUS_MAPPER[value as OrderStatusId] : value as any}
              </div>
            })}
          </Panel>
        </Collapse>
      </>
    )
  }

  return (
    <Modal
        {...modalProps}
        title={t('order.actions.showHistory')}
        centered
        onOk={handleOk}
        onCancel={() => close()}
        footer={[]}
        width="70%"
    >
      <div>
        <Typography.Text>
          {t('order.modalHistory.onlyStates')}
          <Switch style={{ marginLeft: '10px' }} defaultChecked checked={onlyStates} onChange={onChange} />
        </Typography.Text>
      </div>
      <Table
        style={{ marginTop: '5px' }}
        dataSource={processedItems}
        scroll={{ x: '100%', y: '350px' }}
      >
        <Table.Column
          key="resource_id"
          dataIndex=""
          title={t('order.historic.resourceId')}
          width={100}
          align="center"
          render={(value: any) => { return value.resource_id || '' }}
          className='content-start'
        />
        <Table.Column
          key="modifiedRecord"
          dataIndex=""
          title={t('issues.fields.changes')}

          render={(value: any, index: number) => {
            return <>
              {/* {console.log(value)} */}
              {value.insertedResource
              // TODO: make an collapsible to show inserted resource
                ? renderNewResource(value, index)
                : Object.entries(value.modifiedRecord).map(([key, changes]) => {
                  return <div key={`${index}-${key}`}>
                    <b>{t(`order.historic.${key}`)}</b>
                    {': '}
                    {renderHistory(key, changes as RecordChanges)}
                  </div>
                })}
            </>
          }}
        />
        <Table.Column
          key="created_at"
          dataIndex="created_at"
          title={t('issues.fields.created_at')}
          align="center"
          render={(value: any) => { return formatDateTime(value) }}
          className='content-start'
        />
        {<Table.Column
          key="updated_by"
          dataIndex=""
          title={t('issues.fields.updated_by')}
          align="center"
          render={(value, record, index) => {
            return (!value.record.updated_by) ? renderUpdatedByColunm(index) : value.record.updated_by
          }}
          className='content-start'
        />}
      </Table>
    </Modal>
  )
}
