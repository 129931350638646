import { useTranslate } from '@pankod/refine-core'
import { Button, Modal, ModalProps, Steps } from '@pankod/refine-antd'
import { useState } from 'react'

type EmployeeRequestModalProps = {
  modalProps: ModalProps,
  close: any,
  requests: any[],
  approveRejectRequest: Function,
  getPendingEmployeeRequest: Function
}

export const EmployeeRequestModal: React.FC<EmployeeRequestModalProps> = ({ modalProps, close, requests, approveRejectRequest, getPendingEmployeeRequest }) => {
  const t = useTranslate()

  const [current, setCurrent] = useState(0)

  const closeModal = async () => {
    await getPendingEmployeeRequest()
    close()
  }

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    marginTop: 16
  }

  const items = requests.map((item) => ({
    key: item.id,
    title: item.user_id.email,
    content: <>
      <p>{t('employee_absent.approveModal.user', { user: item.user_id.email })}</p>
      <p>{t('employee_absent.approveModal.type', { type: t(`profile.form.${item.type}`) })}</p>
      <p>{t('employee_absent.approveModal.dateStart', { value: item.date_start })}</p>
      <p>{t('employee_absent.approveModal.dateEnd', { value: item.date_end })}</p>
      <p>{t('employee_absent.approveModal.hours', { value: item.hours })}</p>
      <p>{t('employee_absent.approveModal.reason', { value: item.reason })}</p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', gap: '1rem' }}>
        <Button style={{ backgroundColor: '#f16868', color: 'whitesmoke' }} onClick={async () => {
          await approveRejectRequest(item, 'cancel')
          if (current < requests.length - 1) next()
          else if (current === requests.length - 1) closeModal()
        }}>
          {t('employee_absent.approveModal.reject')}
        </Button>
        <Button style={{ backgroundColor: '#67be23', color: 'whitesmoke' }} onClick={async () => {
          await approveRejectRequest(item, 'approve')
          if (current < requests.length - 1) next()
          else if (current === requests.length - 1) closeModal()
        }}>
          {t('employee_absent.approveModal.approve')}
        </Button>
      </div>
    </>
  }))

  return (
    <Modal
      width={800}
      {...modalProps}
      title={t('employee_absent.approveModal.title')}
      centered
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
    >
      {(items.length > 0)
        ? <>
          <Steps size="small" current={current}>
            {items.map(item => (
              <Steps.Step key={item.key} title={item.title} />
            ))}
          </Steps>
          <div style={contentStyle}>{items[current].content}</div><div style={{ marginTop: 24 }}>
            {current < requests.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                {t('employee_absent.approveModal.next')}
              </Button>
            )}
            {current === requests.length - 1 && (
              <Button type="primary" onClick={() => closeModal()}>
                {t('employee_absent.approveModal.finish')}
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                {t('employee_absent.approveModal.prev')}
              </Button>
            )}
          </div>
        </>
        : null}

    </Modal>
  )
}
