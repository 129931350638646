import { Table, useEditableTable } from '@pankod/refine-antd'
import { HttpError, useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { formatDateTime, formatTime } from 'utility'

type EmployeeTimesProps = {
  user: definitions['users'] | undefined
}

export const EmployeeTimes: React.FC<EmployeeTimesProps> = ({ user }) => {
  const t = useTranslate()

  const {
    tableProps,
    setId: setEditId
  } = useEditableTable<definitions['employee_times'], HttpError>({
    resource: 'employee_times',
    initialSorter: [
      {
        field: 'date_start',
        order: 'desc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'user_id',
        operator: 'eq',
        value: user?.id
      }
    ],
    initialPageSize: 10,
    syncWithLocation: false,
    metaData: {
      select: '*'
    }
  })

  return <div>
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(record) => ({
        onClick: (event: any) => {
          setEditId && setEditId(record.id)
        }
      })}
    >
      <Table.Column
        key="date_start"
        dataIndex="date_start"
        title={t('employee_times.fields.date_start')}
        align='left'
        render={(value) => <p>{formatDateTime(value, true)}</p>}
      />
      <Table.Column
        key="time_start"
        dataIndex="date_start"
        title={t('employee_times.fields.time_start')}
        align='left'
        render={(value) => <p>{formatTime(value)}</p>}
      />
      <Table.Column
        key="date_end"
        dataIndex="date_end"
        title={t('employee_times.fields.date_end')}
        align='left'
        render={(value) => <p>{formatDateTime(value, true)}</p>}
      />
      <Table.Column
        key="time_end"
        dataIndex="date_end"
        title={t('employee_times.fields.time_end')}
        align='left'
        render={(value) => <p>{formatTime(value)}</p>}
      />
      <Table.Column
        key="total"
        dataIndex="total"
        title={t('employee_times.fields.duration')}
        align='left'
        render={(value) => {
          const hours = Math.floor(value / (1000 * 60 * 60))
          const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60))
          const seconds = Math.floor((value % (1000 * 60)) / 1000)
          return <p>{`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</p>
        }}
      />
    </Table>
  </div>
}
