/* eslint-disable prefer-const */
import { IResourceComponentsProps } from '@pankod/refine-core'
import { Tabs } from '@pankod/refine-antd'
import { Sale, Supplement } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { currencyValue } from 'utility/currency'
import { MAX_IGIC_VALUE, SUPPLEMENT_PREDEFINED_OPTIONS, processDate } from 'utility'
import { PendingSalesList } from './pendingSales'
import { ReviewedSalesList } from './sentSales'

export const SalesList: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation()

  const supplementsNewRow = (arr: Sale[]) => {
    let supplements: any = []
    arr?.forEach((item) => {
      if (item?.order?.supplements) {
        item?.order?.supplements?.forEach((supplement) => {
          if (supplement) {
            const suppText = supplement?.description
            const obj: Record<string, any> = {}
            const obj2: Record<string, any> = {}
            obj2.invoice = item?.order.invoice
            obj2.invoice_date = item?.order.invoice_date
            obj2.total = item?.order?.total
            obj.order = {
              ...obj2,
              billing_address: item?.order?.billing_address,
              supplements: item?.order?.supplements
            }
            obj.id = supplement.id
            obj.order_id = item?.order?.id
            obj.name = suppText ? (SUPPLEMENT_PREDEFINED_OPTIONS.includes(suppText) ? t(`supplement.descriptions.${suppText}`) : suppText) : t('supplement.fields.volumetric')
            obj.syniva_price = supplement?.supplement
            obj.deleted = supplement?.deleted
            obj.reviewed = supplement?.reviewed
            obj.isSupplement = true
            obj.quantity = 1
            supplements.push(obj)
          }
        })
      }
    })
    supplements = supplements.filter((value: Supplement, index: number, self: any) =>
      index === self.findIndex((s: Supplement) => s.id === value.id)
    )
    return supplements
  }

  const shippingCostNewRows = (items: any) => {
    let rows: any = []

    items?.forEach((i:any) => {
      if (!rows.find((r:any) => r.order_id === i.order.id)) {
        rows.push({
          order: i.order,
          id: i.id,
          order_id: i.order.id,
          name: t('order.fields.shippingPrice'),
          syniva_price: i.order.shipping_price,
          reviewed: i.reviewed,
          quantity: 1
        })
      }
    })

    return rows
  }

  const insuranceNewRows = (items: any) => {
    let rows: any = []

    items?.forEach((i:any) => {
      if (!rows.find((r:any) => r.order_id === i.order.id)) {
        rows.push({
          order: i.order,
          id: i.id,
          order_id: i.order.id,
          name: t('order.fields.shippingInsurance'),
          syniva_price: i.order.shipping_insurance_price,
          reviewed: i.reviewed,
          quantity: 1
        })
      }
    })

    return rows
  }

  const columns = [
    {
      key: 'order.invoice_date',
      title: t('sales.fields.invoiceDate'),
      dataIndex: ['order', 'invoice_date'],
      type: 'date',
      filterType: 'date',
      sorter: true
    },
    {
      key: 'order_id',
      title: t('sales.fields.invoiceNumber'),
      dataIndex: ['order', 'invoice'],
      filterType: 'select',
      entity: 'orders',
      label: 'invoice',
      value: 'id',
      sorter: true
    },
    {
      key: 'order_id',
      title: t('order.fields.id'),
      dataIndex: 'order_id',
      filterType: 'number'
    },
    {
      key: 'order.fields.status',
      title: t('order.fields.status'),
      dataIndex: ['order', 'status', 'name']
    },
    {
      key: 'order.billing_address.name',
      title: t('sales.fields.clientName'),
      dataIndex: ['order', 'billing_address', 'name']
    },
    {
      key: 'order.billing_address.nif',
      title: t('sales.fields.nif'),
      dataIndex: ['order', 'billing_address', 'nif']
    },
    {
      key: 'order.billing_address.city',
      title: t('sales.fields.province'),
      dataIndex: ['order', 'billing_address', 'city']
    },
    {
      key: 'order.billing_address.postal_code',
      title: t('sales.fields.postalCode'),
      dataIndex: ['order', 'billing_address', 'postal_code']
    },
    {
      key: 'order.payment_method_id',
      title: t('sales.fields.paymentMethod'),
      dataIndex: ['order', 'payment_method', 'name'],
      filterType: 'select',
      entity: 'payment_methods',
      label: 'name',
      value: 'id',
      selectFilters: [{ field: 'deleted', operator: 'eq', value: false }]
    },
    {
      key: 'marketplace_id',
      title: t('sales.fields.marketplace'),
      dataIndex: ['marketplace', 'name'],
      filterType: 'select',
      entity: 'marketplaces',
      label: 'name',
      value: 'id',
      selectFilters: [{ field: 'deleted', operator: 'eq', value: false }]
    },
    {
      key: 'name',
      title: t('sales.fields.itemName'),
      dataIndex: 'name',
      filterType: 'select',
      entity: 'items',
      label: 'name',
      value: 'name',
      selectType: 'string',
      selectFilters: [{ field: 'deleted', operator: 'eq', value: false }]
    },
    {
      key: 'item_category_id',
      title: t('sales.fields.itemCategory'),
      dataIndex: ['category', 'description'],
      filterType: 'select',
      entity: 'item_categories',
      label: 'description',
      value: 'id'
    },
    {
      key: 'quantity',
      title: t('sales.fields.quantity'),
      dataIndex: 'quantity',
      filterType: 'number'
    },
    {
      key: 'syniva_price',
      title: t('sales.fields.basePrice'),
      dataIndex: 'syniva_price',
      filterType: 'number',
      type: 'currency'
    },
    {
      key: 'marketplace.igic.value',
      title: t('sales.fields.igicPercentage'),
      dataIndex: ['marketplace', 'igic_types', 'value'],
      type: 'igicPercent'
    },
    {
      title: t('sales.fields.igicValue'),
      type: 'igicValue'
    },
    {
      title: t('sales.fields.subtotal'),
      type: 'subtotal'
    },
    {
      key: 'order.total',
      title: t('sales.fields.totalInvoice'),
      dataIndex: ['order', 'total'],
      type: 'orderTotal'
    },
    {
      key: 'order.delivered_date',
      title: t('sales.fields.delivered_date'),
      dataIndex: ['order', 'delivered_date'],
      type: 'date',
      filterType: 'date'
    }
  ]

  const createRowForExport = (productItems: any, concept: string, shippingPrice: any, showCategory: boolean, isProduct: boolean, economyMethodId: number) => {
    let order = productItems[0]?.order
    let isEconomy = false
    if (order.shipping_method_id && economyMethodId === order.shipping_method_id) {
      isEconomy = true
    }
    if (!isProduct) {
      const _order = productItems.find((x: { order: { status: undefined } }) => x.order.status !== undefined)
      if (_order) {
        order = _order.order
      }
    }

    const address = order?.billing_address
    const quantity = productItems.reduce((acc: number, currVal: { quantity: number }) => acc + currVal.quantity, 0)
    // eslint-disable-next-line camelcase
    let productPrice = productItems.reduce((acc: number, currVal: { quantity: number; syniva_price: any }) => acc + (currVal.quantity * (currVal.syniva_price || 0)), 0)
    if (isEconomy) {
      // eslint-disable-next-line camelcase
      productPrice = productItems.reduce((acc: number, currVal: { quantity: number; price: any; syniva_price: any }) => acc + (currVal.quantity * (currVal.price || currVal.syniva_price || 0)), 0)
    }

    const mustHaveIgic = isProduct ? order?.syniva_price > MAX_IGIC_VALUE : false
    let marketplaceIgic = mustHaveIgic ? productItems[0]?.marketplace?.igic_types?.value || 0 : 0
    if (mustHaveIgic && productItems[0]?.marketplace?.name && productItems[0]?.marketplace?.name.toLowerCase() === 'pedido personalizado') {
      const igicCustomOrder = order.total - order.syniva_price - shippingPrice + (order.discount || 0) - (order.wallet_amount_used || 0)
      marketplaceIgic = Math.round((igicCustomOrder * 100) / order.syniva_price)
    }

    const igic = mustHaveIgic ? currencyValue((productPrice * marketplaceIgic) / 100) : 0
    const supplementsToProcess = productItems[0]?.order?.supplements.filter((x: { description: string }) => x.description !== 'reducedIva' &&
      x.description !== 'reducedIvaSecondHand' &&
      x.description !== 'reducedIvaBreakdown' &&
      x.description !== 'reducedIvaEU' &&
      x.description !== 'reducedIvaShipment' &&
      x.description !== 'unified' &&
      x.description !== 'igic' &&
      x.description !== 'shippingInsurancePrice' &&
      x.description !== 'refundByCancelation' &&
      x.description !== 'priceChange')
    const supplementAmount = supplementsToProcess.map((s: Supplement) => s.supplement || 0).reduce((a: number, b: number) => a + (b), 0)

    if (!isProduct) {
      // eslint-disable-next-line camelcase
      productPrice = productItems.reduce((acc: number, currVal: { quantity: number; syniva_price: any }) => acc + currVal.syniva_price || 0, 0)
    }

    const walletUsed = (isProduct && order?.wallet_amount_used > 0) ? parseFloat(`${order?.wallet_amount_used}`) || 0 : 0
    const discountsUsed = (isProduct && order?.discount > 0) ? parseFloat(`${order?.discount}`) || 0 : 0
    const totalDiscounts = (Math.abs(discountsUsed) + Math.abs(walletUsed) !== 0) ? Math.abs(discountsUsed) + Math.abs(walletUsed) : 0

    const reducedProductItems = {
      [t('sales.fields.invoiceDate')]: order?.invoice_date ? processDate(order?.invoice_date) : '',
      [t('sales.fields.invoiceNumber')]: order?.invoice || '',
      [t('order.fields.id')]: productItems[0]?.order_id || '',
      [t('order.fields.status')]: order?.status?.name || '',
      [t('sales.fields.clientName')]: address?.name + ' ' + address?.surnames || '',
      [t('sales.fields.nif')]: address?.nif || '',
      [t('sales.fields.province')]: address?.city || '',
      [t('sales.fields.postalCode')]: address?.postal_code || '',
      [t('sales.fields.paymentMethod')]: order?.payment_method?.name || '',
      [t('sales.fields.marketplace')]: productItems[0]?.marketplace?.name || '',
      [t('sales.fields.itemName')]: concept,
      [t('sales.fields.itemCategory')]: (showCategory) ? productItems[0]?.category?.description || '' : '',
      [t('sales.fields.quantity')]: quantity,
      [t('sales.fields.basePrice')]: productPrice || 0,
      [t('sales.fields.igicPercentage')]: (isProduct) ? marketplaceIgic : 0,
      [t('sales.fields.igicValue')]: (isProduct) ? igic : 0,
      [t('sales.fields.subtotal')]: (isProduct) ? (productPrice || 0) + igic || 0 : (productPrice || 0),
      [t('sales.fields.discount')]: (isProduct) ? parseFloat(`-${totalDiscounts}`) : 0,
      // [t('sales.fields.walletAmountUsed')]: (isProduct && order?.wallet_amount_used > 0) ? parseFloat(`-${order?.wallet_amount_used}`) || '' : '',
      [t('sales.fields.totalInvoice')]: (showCategory) ? order?.total + supplementAmount || '' : '',
      [t('order.fields.delivered_date')]: order?.delivered_date ? processDate(order?.delivered_date) : ''

    }
    return reducedProductItems
  }

  const createIGICRowForExport = (productItems: any, concept: string, shippingPrice: any, showCategory: boolean, isProduct: boolean, economyMethodId: number) => {
    const igicRows : any[] = []
    const keys = Object.keys(productItems)
    let order = productItems[`${keys[0]}`][0]?.order
    const marketplace = productItems[`${keys[0]}`][0]?.marketplace || undefined
    let isEconomy = false
    if (order.shipping_method_id && economyMethodId === order.shipping_method_id) {
      isEconomy = true
    }
    const address = order?.billing_address

    keys.forEach((key: any, index: number) => {
      const quantity = productItems[`${key}`].reduce((acc: number, currVal: { quantity: number }) => acc + currVal.quantity, 0)
      // eslint-disable-next-line camelcase
      let igicValue = productItems[`${key}`].reduce((acc: number, currVal: { igic_price: number, quantity: number }) => acc + (currVal.igic_price), 0)
      if (isEconomy) {
        // eslint-disable-next-line camelcase
        igicValue = productItems[`${key}`].reduce((acc: number, currVal: { price: number, igic_percent: number, quantity: number }) => acc + ((currVal.price * (currVal.igic_percent / 100)) * currVal.quantity), 0)
      }

      // INFO In this case igic value has 0 value and we need take the order igic
      if (igicValue === 0 && key > 0) {
        igicValue = parseFloat((order.igic).toFixed(2))
      }

      // eslint-disable-next-line camelcase
      let productPrice = productItems[`${key}`].reduce((acc: number, currVal: { quantity: number; syniva_price: any }) => acc + (currVal.quantity * (currVal.syniva_price || 0)), 0)
      if (isEconomy) {
        // eslint-disable-next-line camelcase
        productPrice = productItems[`${key}`].reduce((acc: number, currVal: { quantity: number; price: any; syniva_price: any }) => acc + (currVal.quantity * (currVal.price || currVal.syniva_price || 0)), 0)
      }
      // const igic = order.igic
      const supplementsToProcess = order?.supplements.filter((x: { description: string }) => x.description !== 'reducedIva' &&
        x.description !== 'reducedIvaSecondHand' &&
        x.description !== 'reducedIvaBreakdown' &&
        x.description !== 'reducedIvaEU' &&
        x.description !== 'reducedIvaShipment' &&
        x.description !== 'unified' &&
        x.description !== 'shippingInsurancePrice' &&
        x.description !== 'igic' &&
        x.description !== 'refundByCancelation' &&
        x.description !== 'priceChange')

      const supplementAmount = supplementsToProcess.map((s: Supplement) => s.supplement || 0).reduce((a: number, b: number) => a + b, 0)

      const walletUsed = (isProduct && order?.wallet_amount_used > 0) ? parseFloat(`${order?.wallet_amount_used}`) || 0 : 0
      const discountsUsed = (isProduct && order?.discount > 0) ? parseFloat(`${order?.discount}`) || 0 : 0
      const totalDiscounts = (Math.abs(discountsUsed) + Math.abs(walletUsed) !== 0) ? Math.abs(discountsUsed) + Math.abs(walletUsed) : 0

      const reducedIgicRow = {
        [t('sales.fields.invoiceDate')]: order?.invoice_date ? processDate(order?.invoice_date) : '',
        [t('sales.fields.invoiceNumber')]: order?.invoice || '',
        [t('order.fields.id')]: productItems[`${key}`][0]?.order_id || '',
        [t('order.fields.status')]: order?.status?.name || '',
        [t('sales.fields.clientName')]: address?.name + ' ' + address?.surnames || '',
        [t('sales.fields.nif')]: address?.nif || '',
        [t('sales.fields.province')]: address?.city || '',
        [t('sales.fields.postalCode')]: address?.postal_code || '',
        [t('sales.fields.paymentMethod')]: order?.payment_method?.name || '',
        [t('sales.fields.marketplace')]: marketplace?.name || '',
        [t('sales.fields.itemName')]: (isProduct) ? `${concept} IGIC (${key}%)` : concept,
        [t('sales.fields.itemCategory')]: (showCategory) ? productItems[`${key}`][0]?.category?.description || '' : '',
        [t('sales.fields.quantity')]: quantity,
        [t('sales.fields.basePrice')]: productPrice || 0,
        [t('sales.fields.igicPercentage')]: (isProduct) ? parseFloat(key) : 0,
        [t('sales.fields.igicValue')]: (isProduct) ? igicValue : 0,
        [t('sales.fields.subtotal')]: (isProduct) ? (productPrice || 0) + igicValue || 0 : (productPrice || 0),
        [t('sales.fields.discount')]: (index === 0) ? parseFloat(`-${totalDiscounts}`) : 0,
        // [t('sales.fields.walletAmountUsed')]: (isProduct && order?.wallet_amount_used > 0) ? parseFloat(`-${order?.wallet_amount_used}`) || '' : '',

        [t('sales.fields.totalInvoice')]: (showCategory && index === 0) ? order?.total + supplementAmount || '' : '',
        [t('order.fields.delivered_date')]: order?.delivered_date ? processDate(order?.delivered_date) : ''
      }
      igicRows.push(reducedIgicRow)
    })

    return igicRows
  }

  const prepareIGICItems = (productItems: any, shippingPrice: number) => {
    const sortedOrders: any = {}

    const order = productItems[0].order
    const marketplace = productItems[0].marketplace

    productItems.forEach((item: any) => {
      if (item?.igic_percent) {
        sortedOrders[`${item.igic_percent}`] = [...sortedOrders[`${item.igic_percent}`] || [], item]
      } else {
        if (order.syniva_price < MAX_IGIC_VALUE) {
          sortedOrders['0'] = [...sortedOrders['0'] || [], item]
        } else {
          let marketplaceIgic = marketplace?.igic_types?.value || 0
          if (marketplace?.name && marketplace?.name.toLowerCase() === 'pedido personalizado') {
            const igicCustomOrder = order.total - order.syniva_price - shippingPrice + (order.discount || 0) - (order.wallet_amount_used || 0)
            marketplaceIgic = Math.round((igicCustomOrder * 100) / order.syniva_price)
          }
          sortedOrders[`${marketplaceIgic}`] = [...sortedOrders[`${marketplaceIgic}`] || [], item]
        }
      }
    })
    return sortedOrders
  }

  const dataToExport = (items: any[], mode: string, economyShimentId: number) => {
    if (mode === 'all') {
      return items?.map((item: Sale) => {
        const order = item?.order
        const address = order?.billing_address
        const mustHaveIgic = order?.syniva_price > MAX_IGIC_VALUE
        const marketplaceIgic = mustHaveIgic ? item?.marketplace?.igic_types?.value || 0 : 0
        const igic = mustHaveIgic ? currencyValue((((item?.syniva_price || 0) * marketplaceIgic) / 100) * item.quantity) : 0
        let itemPrice = (item.syniva_price || 0) * item.quantity

        if (order.shipping_method_id && economyShimentId === order.shipping_method_id) {
          itemPrice = (item.price || item.syniva_price || 0) * item.quantity
        }

        const supplementAmount = item?.order?.supplements
          .map((s: Supplement) => s.supplement || 0)
          .reduce((a: number, b: number) => a + b, 0)
        return {
          [t('sales.fields.invoiceDate')]: item?.order?.invoice_date ? processDate(item?.order?.invoice_date) : '',
          [t('sales.fields.invoiceNumber')]: item?.order?.invoice || '',
          [t('order.fields.id')]: item?.order_id || '',
          [t('order.fields.status')]: order?.status?.name || '',
          [t('sales.fields.clientName')]: address?.name + ' ' + address?.surnames || '',
          [t('sales.fields.nif')]: address?.nif || '',
          [t('sales.fields.province')]: address?.city || '',
          [t('sales.fields.postalCode')]: address?.postal_code || '',
          [t('sales.fields.paymentMethod')]: item?.order?.payment_method?.name || '',
          [t('sales.fields.marketplace')]: item?.marketplace?.name || '',
          [t('sales.fields.itemName')]: item?.name || '',
          [t('sales.fields.itemCategory')]: item?.category?.description || '',
          [t('sales.fields.quantity')]: item?.quantity || 0,
          [t('sales.fields.basePrice')]: itemPrice || 0,
          [t('sales.fields.igicPercentage')]: marketplaceIgic,
          [t('sales.fields.igicValue')]: igic,
          [t('sales.fields.subtotal')]: (itemPrice || 0) + igic || 0,
          [t('sales.fields.totalInvoice')]: order?.total + supplementAmount,
          // [t('sales.fields.discount')]: (order?.discount > 0) ? `-${order?.discount}` || '' : '',
          [t('order.fields.delivered_date')]: order?.delivered_date ? processDate(order?.delivered_date) : ''

        }
      })
    } else if (mode === 'noigic') {
      const preparedItems: { [x: string]: any }[] = []

      const idList = new Set()

      items?.forEach((item: any) => {
        idList.add(item?.order_id)
      })

      idList.forEach((id) => {
        const itemsOfOrder = items?.filter(x => x.order_id === id && x.order.syniva_price <= MAX_IGIC_VALUE && x.order.igic === 0)

        // eslint-disable-next-line camelcase
        let shippingPrice = itemsOfOrder.filter(x => x.name.toLowerCase() === 'seguro de transporte' || x.name.toLowerCase() === 'coste envío').reduce((acc: number, currVal: { syniva_price: any }) => acc + currVal.syniva_price || 0, 0)
        if (shippingPrice < 0) {
          shippingPrice = 0
        }

        if (itemsOfOrder) {
          const productItems = itemsOfOrder.filter(x => x.marketplace !== undefined && x.marketplace?.name !== '')
          if (productItems && productItems.length > 0) {
            const igicApply = createRowForExport(productItems, t('sales.export.products'), shippingPrice, true, true, economyShimentId)
            preparedItems.push(igicApply)
          }

          let transportItems = itemsOfOrder.filter(x => x.name.toLowerCase() === 'seguro de transporte' || x.name.toLowerCase() === 'coste envío' || x.name.toLowerCase() === 'coste envio')

          if (productItems[0]?.order?.supplements && productItems[0]?.order?.supplements.length > 0) {
            const supplementOrderList = productItems[0]?.order?.supplements

            const tariffSup = supplementOrderList.filter((s: any) => s.description === 'tariff') || undefined
            const trPeninsula = supplementOrderList.filter((s: any) => s.description === 'transportToPeninsula') || undefined
            const transportSupplement = supplementOrderList.filter((s: any) => s.description === 'transportSupplement') || undefined
            const volSup = supplementOrderList.filter((s: any) => s.volumetric === true) || undefined
            const refundTransport = supplementOrderList.filter((s: any) => s.description === 'refundTransport') || undefined
            const refundStore = supplementOrderList.filter((s: any) => s.description === 'refundStore') || undefined
            const refund = supplementOrderList.filter((s: any) => s.description === 'refund') || undefined
            // const refundByCancelation = supplementOrderList.filter((s: any) => s.description === 'refundByCancelation') || undefined
            const otherSup = supplementOrderList.filter((s: any) => s.description === 'other') || undefined

            if (tariffSup) tariffSup.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (trPeninsula) trPeninsula.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (transportSupplement) transportSupplement.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (volSup) volSup.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (refundTransport) refundTransport.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (refundStore) refundStore.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (refund) refund.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            // if (refundByCancelation) refundByCancelation.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (otherSup) otherSup.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
          }

          // Check if penalty
          if (transportItems[0]?.order?.supplements && transportItems[0]?.order?.supplements.length > 0) {
            const trPenalty = transportItems[0].order.supplements.find((s: any) => s.description === 'penalization')
            if (trPenalty) {
              transportItems.push({
                id: trPenalty.id,
                name: trPenalty.description,
                order: transportItems[0].order,
                order_id: trPenalty.order_id,
                quantity: 1,
                reviewed: undefined,
                saleId: transportItems[0].saleId,
                syniva_price: trPenalty.supplement
              })
            }
          }

          // Check if custom order
          if (transportItems[0]?.order?.custom_order_price && itemsOfOrder[0]?.marketplace?.id === 0) {
            transportItems.push({
              id: transportItems[0]?.order?.id,
              name: 'custom_order_price',
              order: transportItems[0].order,
              order_id: transportItems[0].order.id,
              quantity: 1,
              reviewed: undefined,
              saleId: transportItems[0].saleId,
              syniva_price: transportItems[0].order.custom_order_price
            })
          }

          transportItems = transportItems.filter(x => x.marketplace === undefined)
          if (transportItems.length > 0) {
            const transportRow = createRowForExport(transportItems, t('sales.export.transportCost'), 0, false, false, economyShimentId)
            preparedItems.push(transportRow)
          }
        }
      })
      return preparedItems
    } else if (mode === 'igic') {
      const preparedItems: { [x: string]: any }[] = []

      const idList = new Set()

      items?.forEach((item: any) => {
        idList.add(item?.order_id)
      })

      idList.forEach((id) => {
        const itemsOfOrder = items?.filter(x => x.order_id === id && x.order.syniva_price >= MAX_IGIC_VALUE && x.order.igic > 0)

        // eslint-disable-next-line camelcase
        let shippingPrice = itemsOfOrder.filter(x => x.name.toLowerCase() === 'seguro de transporte' || x.name.toLowerCase() === 'coste envío').reduce((acc: number, currVal: { syniva_price: any }) => acc + currVal.syniva_price || 0, 0)
        if (shippingPrice < 0) {
          shippingPrice = 0
        }

        if (itemsOfOrder) {
          const productItems = itemsOfOrder.filter(x => x.marketplace !== undefined && x.marketplace?.name !== '')
          if (productItems && productItems.length > 0) {
            const sortedOrders = prepareIGICItems(productItems, shippingPrice)
            const igicApply = createIGICRowForExport(sortedOrders, t('sales.export.products'), shippingPrice, true, true, economyShimentId)
            if (igicApply && igicApply.length > 0) {
              igicApply.forEach((row: any) => {
                preparedItems.push(row)
              })
            }
          }
          let transportItems = itemsOfOrder.filter(x => x.name.toLowerCase() === 'seguro de transporte' || x.name.toLowerCase() === 'coste envío' || x.name.toLowerCase() === 'coste envio')

          if (productItems[0]?.order?.supplements && productItems[0]?.order?.supplements.length > 0) {
            const supplementOrderList = productItems[0]?.order?.supplements

            const tariffSup = supplementOrderList.filter((s: any) => s.description === 'tariff') || undefined
            const trPeninsula = supplementOrderList.filter((s: any) => s.description === 'transportToPeninsula') || undefined
            const transportSupplement = supplementOrderList.filter((s: any) => s.description === 'transportSupplement') || undefined
            const volSup = supplementOrderList.filter((s: any) => s.volumetric === true) || undefined
            const refundTransport = supplementOrderList.filter((s: any) => s.description === 'refundTransport') || undefined
            const refundStore = supplementOrderList.filter((s: any) => s.description === 'refundStore') || undefined
            const refund = supplementOrderList.filter((s: any) => s.description === 'refund') || undefined
            // const refundByCancelation = supplementOrderList.filter((s: any) => s.description === 'refundByCancelation') || undefined
            const otherSup = supplementOrderList.filter((s: any) => s.description === 'other') || undefined

            if (tariffSup) tariffSup.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (trPeninsula) trPeninsula.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (transportSupplement) transportSupplement.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (volSup) volSup.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (refundTransport) refundTransport.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (refundStore) refundStore.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (refund) refund.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            // if (refundByCancelation) refundByCancelation.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
            if (otherSup) otherSup.forEach((x: any) => transportItems.push({ id: x.id, name: x.description, order: productItems[0].order, order_id: x.order_id, quantity: 1, reviewed: undefined, saleId: productItems[0].saleId, syniva_price: x.supplement }))
          }

          // Check if penalty
          if (transportItems[0]?.order?.supplements && transportItems[0]?.order?.supplements.length > 0) {
            const trPenalty = transportItems[0].order.supplements.find((s: any) => s.description === 'penalization')
            if (trPenalty) {
              transportItems.push({
                id: trPenalty.id,
                name: trPenalty.description,
                order: transportItems[0].order,
                order_id: trPenalty.order_id,
                quantity: 1,
                reviewed: undefined,
                saleId: transportItems[0].saleId,
                syniva_price: trPenalty.supplement
              })
            }
          }

          // Check if custom order
          if (transportItems[0]?.order?.custom_order_price && itemsOfOrder[0]?.marketplace?.id === 0) {
            transportItems.push({
              id: transportItems[0]?.order?.id,
              name: 'custom_order_price',
              order: transportItems[0].order,
              order_id: transportItems[0].order.id,
              quantity: 1,
              reviewed: undefined,
              saleId: transportItems[0].saleId,
              syniva_price: transportItems[0].order.custom_order_price
            })
          }

          transportItems = transportItems.filter(x => x.marketplace === undefined)
          if (transportItems.length > 0) {
            const transportRow = createRowForExport(transportItems, t('sales.export.transportCost'), 0, false, false, economyShimentId)
            preparedItems.push(transportRow)
          }
        }
      })
      return preparedItems
    }
  }

  return (
    <Tabs defaultActiveKey='1' size='middle'>
      <Tabs.TabPane tab={t('sales.pendingSales')} key='1'>
        <PendingSalesList
          columns={columns as any[]}
          dataToExport={dataToExport}
          supplementsNewRow={supplementsNewRow}
          insuranceNewRows={insuranceNewRows}
          shippingCostNewRows={shippingCostNewRows}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('sales.reviewedSales')} key='2'>
        <ReviewedSalesList
          columns={columns as any[]}
          dataToExport={dataToExport}
          supplementsNewRow={supplementsNewRow}
          insuranceNewRows={insuranceNewRows}
          shippingCostNewRows={shippingCostNewRows}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}
