import { Table, useEditableTable } from '@pankod/refine-antd'
import { HttpError, useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { formatDateTime } from 'utility'

type EmployeeAbsentProps = {
  user: definitions['users'] | undefined
}

export const EmployeeAbsent: React.FC<EmployeeAbsentProps> = ({ user }) => {
  const t = useTranslate()

  const {
    tableProps,
    setId: setEditId
  } = useEditableTable<definitions['employee_absent'], HttpError>({
    resource: 'employee_absent',
    initialSorter: [
      {
        field: 'date_start',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'user_id',
        operator: 'eq',
        value: user?.id
      }
    ],
    initialPageSize: 5,
    syncWithLocation: false,
    metaData: {
      select: '*'
    }
  })

  return <div>
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(record) => ({
        onClick: (event: any) => {
          setEditId && setEditId(record.id)
        }
      })}
    >
      <Table.Column
        key="type"
        dataIndex="type"
        title={t('employee_absent.fields.type')}
        align='left'
        render={(value) => t(`profile.form.${value}`)}
      />
      <Table.Column
        key="date_start"
        dataIndex="date_start"
        title={t('employee_absent.fields.dateStart')}
        align='left'
        render={(value) => <p>{formatDateTime(value, true)}</p>}
      />
      <Table.Column
        key="date_end"
        dataIndex="date_end"
        title={t('employee_absent.fields.dateEnd')}
        align='left'
        render={(value) => <p>{formatDateTime(value, true)}</p>}
      />
      <Table.Column
        key="hours"
        dataIndex="hours"
        title={t('employee_absent.fields.hours')}
        align='left'
      />
      <Table.Column
        key="status"
        dataIndex="status"
        title={t('employee_absent.fields.status')}
        align='left'
      />
      <Table.Column
        key="approved_by"
        dataIndex="approved_by"
        title={t('employee_absent.fields.approved_by')}
        align='left'
        render={(_value, record: any) => <p>{record.approved_by?.email || null}</p>}
      />
    </Table>
  </div>
}
