import { Button, Input } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

type UserMenuContentProps = {
  user: any,
  registryEmployeeData: any,
  entryData: any,
  note: any,
  setNote: any,
  showProfileLink: boolean
}

export const UserMenuContent: React.FC<UserMenuContentProps> = ({ user, registryEmployeeData, entryData, note, setNote, showProfileLink }) => {
  const t = useTranslate()

  return <div>
    {(showProfileLink)
      ? <div>
        <a href={`/profile/show/${user?.user_metadata?.id}`}>{t('profile.goToProfile')}</a>
      </div>
      : null}
    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between', alignContent: 'center', gap: '0.5rem' }}>
      <Button onClick={() => registryEmployeeData()}>{(entryData) ? t('profile.out') : t('profile.in')}</Button>
      <p style={{ marginTop: '0.25rem' }}>
        {(entryData && entryData.date_start)
          ? t('profile.inTime', {
            day: new Date(entryData.date_start).getDate(),
            month: new Date(entryData.date_start).getMonth() + 1,
            year: new Date(entryData.date_start).getFullYear(),
            hours: new Date(entryData.date_start).getHours(),
            mins: new Date(entryData.date_start).getMinutes()
          })
          : t('profile.noTime')}
      </p>
    </div>
    {(entryData && entryData.date_start)
      ? <div style={{ marginTop: '0.25rem', display: 'flex', justifyContent: 'space-between', alignContent: 'center', gap: '0.5rem' }}>
        <Input.TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder={t('profile.note')}
          autoSize={{ minRows: 2, maxRows: 6 }} />
      </div>
      : null}
  </div>
}
