import { Button, Icons, Modal, Typography, useModal } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { FRONT_MAIN_URL } from 'utility'
import supabaseSuper from 'utility/superClient'

type MagicLinkButtonProps = {
  email: string
}

export const MagicLinkButton: React.FC<MagicLinkButtonProps> = ({ email }) => {
  const { modalProps, show, close } = useModal()
  const { open } = useNotification()
  const t = useTranslate()

  const sendMagicLink = async () => {
    try {
      const { error } = await supabaseSuper.auth.api.sendMagicLinkEmail(email, {
        // set this to false if you do not want the user to be automatically signed up
        shouldCreateUser: false,
        redirectTo: FRONT_MAIN_URL
      })
      if (error) throw Error(error.message)
      open?.({
        type: 'success',
        message: t('magicLink.successMessage', { email: email }),
        description: t('magicLink.successDescription')
      })
      close()
    } catch (error) {
      open?.({
        type: 'error',
        description: t('magicLink.errorSend'),
        message: ''
      })
      console.error(error)
    }
  }

  return (
    <>
    <Button onClick={() => show()} danger size="small">
      <Icons.RocketOutlined style={{ color: '#fb7e54' }} />
    </Button>

    <Modal
      {...modalProps}
      title={t('magicLink.title')}
      centered
      onOk={() => sendMagicLink()}
      okText={t('magicLink.send')}
      onCancel={close}
      style={{ minWidth: '40svw' }}
    >
      <Typography>{t('magicLink.advise', { email: email })}</Typography>
    </Modal>
    </>
  )
}
